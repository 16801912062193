import { useMutation } from 'react-query';

import { db } from 'src/utils/firebase';

type SendMailArgs = {
  to: string;
  html: string;
  subject: string;
};

async function sendMail({ to, html, subject }: SendMailArgs) {
  await db.collection('mail').doc().set({
    to,
    message: {
      html,
      subject,
    },
  });
}

function useSendMail() {
  return useMutation(sendMail);
}

export { useSendMail };
