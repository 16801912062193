import { useMutation } from 'react-query';
import { db } from 'src/utils/firebase';

type AddSubscriberResponse = {
  subscriberAlreadyExist: boolean;
};

export const addSubscriber = async ({
  email,
}): Promise<AddSubscriberResponse> => {
  const subscriberSnapshot = await db
    .collection('subscribers')
    .where('email', '==', email)
    .get();

  if (subscriberSnapshot.size > 0) {
    return {
      subscriberAlreadyExist: true,
    };
  } else {
    await db.collection('subscribers').add({
      email,
    });
    return {
      subscriberAlreadyExist: false,
    };
  }
};

const useAddSubscriber = () => {
  return useMutation(addSubscriber);
};

export { useAddSubscriber };
