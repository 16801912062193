import { useQuery, BaseQueryOptions } from 'react-query';
import { camelizeKeys } from 'humps';

import { db } from 'src/utils/firebase';
import {
  Visa,
  Requirement,
  Faqs,
  RequirementCategory,
  Country,
  OkToBoardItem,
} from 'src/types';
import { VISA_TYPES_LABELS } from 'src/constants';

const getVisa = async (
  _,
  {
    countryCode,
    visaType,
    isApplicationCompleted,
    isFetchReqs = true,
    isFetchFaqs = true,
    isFetchOkToBoardItems = true,
  }
): Promise<Visa | null> => {
  //getting country Id from display Name
  const countrySnapshot = await db
    .collection('countries')
    .where('country_code', '==', countryCode)
    .get();

  if (countrySnapshot.docs[0].exists) {
    const countryId = countrySnapshot.docs[0].id;

    const visaSnapshot = await db
      .collection('visas')
      .where('country_id', '==', countryId)
      .where('visa_type', '==', visaType)
      .get();

    if (visaSnapshot.docs[0].exists) {
      // If Visa is available then get it's id
      const doc = visaSnapshot.docs[0].data();
      const visaId = visaSnapshot.docs[0].id;

      const requirements: Requirement[] = [];
      if (isFetchReqs) {
        const fetchedRequirements = doc?.requirements;
        for (let i = 0; i < fetchedRequirements.length; i++) {
          const req = fetchedRequirements[i];
          const requirementSnapshot = await req.get();
          // Rename fields to camelCase
          let requirement = requirementSnapshot.data();

          //to check if requirement is an active requirement and application is not completed
          //for completed application we need to show all the requirements that user filled
          if (isApplicationCompleted || !requirement.deleted) {
            const categorySpanshot = await requirement.category.get();
            const categoryData: RequirementCategory = categorySpanshot.data();
            requirement = { ...requirement, category: categoryData.name };
            requirements.push(
              camelizeKeys({ ...requirement, id: requirementSnapshot.id })
            );
          }
        }
      }

      //getting faqs answer
      const faqs: Faqs[] = [];
      if (isFetchFaqs) {
        const fetchedFaqs = doc?.faqs_answers;
        for (let i = 0; i < fetchedFaqs.length; i++) {
          const faqAnswer = fetchedFaqs[i];
          const faqSnapshot = await faqAnswer.faq.get();
          // Rename fields to camelCase
          const faqData = faqSnapshot.data();
          faqs.push(
            camelizeKeys({
              ...faqData,
              id: faqSnapshot.id,
              answer: faqAnswer.answer,
            })
          );
        }
      }

      const okToBoardItems: OkToBoardItem[] = [];

      if (isFetchOkToBoardItems) {
        const fetchedOkToBoardItems = doc?.ok_to_board_items;

        for (let i = 0; i < fetchedOkToBoardItems.length; i++) {
          const item = fetchedOkToBoardItems[i];
          const itemDataSnapshot = await item.get();
          const itemData = itemDataSnapshot.data();
          okToBoardItems.push(
            camelizeKeys({
              ...itemData,
              id: itemDataSnapshot.id,
            })
          );
        }
      }

      return camelizeKeys({
        av_success_rate: doc?.av_success_rate,
        country_id: doc?.country_id,
        country_short_name: doc?.country_short_name,
        processing_time: doc?.processing_time,
        success_rate: doc?.success_rate,
        visa_type: VISA_TYPES_LABELS[doc?.visa_type],
        visa_duration_options: doc?.visa_duration_options,
        id: visaId,
        requirements,
        faqs,
        okToBoardItems,
        countryCode,
      });
    }
  }
  return null;
};

type GetVisaArgs = {
  countryCode: string;
  visaType: number;
  isApplicationCompleted: boolean;
  isFetchReqs?: boolean;
  isFetchFaqs?: boolean;
  isFetchOkToBoardItems?: boolean;
};

const useGetVisa = ({
  countryCode,
  visaType,
  isApplicationCompleted,
  isFetchReqs,
  isFetchFaqs,
  isFetchOkToBoardItems,
}: GetVisaArgs) => {
  return useQuery(
    [
      'visa',
      {
        countryCode,
        visaType,
        isApplicationCompleted,
        isFetchReqs,
        isFetchFaqs,
        isFetchOkToBoardItems,
      },
    ],
    getVisa,
    {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
    }
  );
};

const getTopVisas = async (_, { countries }): Promise<Visa[] | null> => {
  const snapshot = await db
    .collection('visas')
    .where('top_visa', '==', true)
    .get();
  const topVisas = await Promise.all(
    snapshot.docs.map(async doc => {
      const visa = doc.data();
      const country: Country = countries.find(c => c.id === visa.country_id);
      return {
        av_success_rate: visa.av_success_rate,
        country_id: visa.country_id,
        processing_time: visa.processing_time,
        success_rate: visa.success_rate,
        visa_type: visa.visa_type,
        visa_duration_options: visa.visa_duration_options,
        id: doc.id,
        country_short_name: country.shortName,
        country_code: country.countryCode,
      };
    })
  );
  return camelizeKeys([...topVisas]);
};

const useGetTopVisas = (countries, options: BaseQueryOptions) => {
  return useQuery(['top-visas', { countries }], getTopVisas, {
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  });
};

export { useGetVisa, useGetTopVisas };
