import styled, { css } from 'styled-components';
import { Box } from 'design/atoms/Box';

interface NavItemsGroupStyledProps {
  align: 'left' | 'right';
}

export const NavItemsGroupStyled = styled(Box)(
  (props: NavItemsGroupStyledProps) => css`
    display: flex;
    margin-left: ${props.align === 'left' ? 'initial' : 'auto'};
    @media screen and (max-width: 767px) {
      margin-left: 0;
      flex-direction: column;
    }
  `
);
