import React from 'react';
import { ReactQueryDevtools } from 'react-query-devtools';

import AppProviders from './AppProviders';
import GlobalStyles from './styles/GlobalStyles';
import AppRoutes from './routes';
import Maintenance from './pages/Maintenance';

const App = () => {
  return (
    <AppProviders>
      <GlobalStyles />
      {process.env.REACT_APP_MAINTENANCE_MODE === 'true' ? (
        <Maintenance />
      ) : (
        <AppRoutes />
      )}
      {process.env.NODE_ENV === 'development' && <ReactQueryDevtools />}
    </AppProviders>
  );
};

export default App;
