import React from 'react';
import { STFlex } from '../../atoms/Container';

export const STHeader = ({ children }) => {
  return (
    <STFlex
      height={['36px', '65px']}
      as="header"
      width={'100%'}
      justifyContent="center"
      bg="navBackground"
    >
      {children}
    </STFlex>
  );
};
