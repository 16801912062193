import React from 'react';
import nprogress from 'nprogress';

// doneCondition: condition to stop loader
export const useLoader = (args: {
  startCondition?: boolean;
  doneCondition: boolean;
}) => {
  React.useEffect(() => {
    if (args.hasOwnProperty('startCondition') && args.startCondition) {
      nprogress.start();
    } else if (!args.hasOwnProperty('startCondition')) {
      nprogress.start();
    }

    if (args.doneCondition) {
      nprogress.done();
    }
  }, [args]);
};

export const usePrevious = val => {
  const valRef = React.useRef();

  React.useEffect(() => {
    valRef.current = val;
  });

  return valRef.current;
};
