function getBankStateEmailTemplate(url) {
  return `
  <!DOCTYPE html>
  <html lang="en">
    <head>
      <meta charset="UTF-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <title>Bank Statement</title>
      <style>
        body {
          height: 100vh;
          margin: 0px;
          padding: 0px;
          font-family: sans-serif;
          font-size: 15px;
        }
        .body {
          background: #eeeeee;
          height: 100%;
          padding: 18px 0px;
        }
        .container {
          width: 50%;
          background: #fff;
          margin: 0px auto;
          padding: 20px 24px;
          border-radius: 4px;
          min-height: 175px;
        }
        #download-btn {
          padding: 8px 14px;
          color: #fff;
          background: #006fae;
          text-decoration: none;
          border-radius: 2px;
        }
        header {
          width: 100%;
          display: flex;
          justify-content: space-between;
          border-bottom: 1px solid #eeeeee;
        }
        img {
          margin-bottom: 6px;
        }
        p {
          margin-bottom: 20px;
        }
        #copyright-text {
          width: calc(50% + 44px);
          margin: 0 auto;
          margin-top: 14px;
          font-size: 13px;
        }
        @media screen and (max-width: 520px) {
          body {
            font-size: 14px;
          }
          h1 {
            font-size: 24px;
          }
          p {
            margin-bottom: 24px;
          }
          .container {
            width: 85%;
            padding: 10px 16px;
          }
          #copyright-text {
            width: calc(85% + 32px);
          }
        }
      </style>
    </head>
    <body>
      <div class="body">
        <div class="container">
          <header>
            <img width="180" src="https://res-3.cloudinary.com/crunchbase-production/image/upload/c_lpad,f_auto,q_auto:eco/sr9qncmvebdduwxkoxzl" alt="Sastaticket logo" />
          </header>
          <p>
            Thank you for using Sastaticket for generating this document. Please click
            the download button below to download the document.
          </p>
          <div>
            <a id="download-btn" href="${url}" download>
              Download
            </a>
          </div>
        </div>
        <p id="copyright-text">
          Copyright © 2020 Sastaticket, Inc. All rights reserved.
        </p>
      </div>
    </body>
  </html>
  `;
}

export { getBankStateEmailTemplate };
