import React, { Suspense } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useLocation,
} from 'react-router-dom';
import { isMobile } from 'react-device-detect';

import routes, { PATHS } from './routesConfig';
import ProtectedRoute from './ProtectedRoute';
import Navigation from '../components/Navigation';
import { useAuth } from 'src/AppProviders/contexts';
import { useLoader } from 'src/hooks';
import { STFlex } from 'design/atoms/Container';
import Footer from 'src/components/Footer';
import { Box } from 'design/atoms/Box';
import { BaseText } from 'design/atoms/Text';

const Loader = ({ currentLocation }) => {
  const [done, setDone] = React.useState(false);
  const location = useLocation();
  React.useEffect(() => {
    setDone(true);
    currentLocation(location);
    //eslint-disable-next-line
  }, [location]);

  useLoader({ doneCondition: done });

  return null;
};

const AppRoutes: React.FC = () => {
  const [position, setPosition] = React.useState('absolute');
  const [location, setLocation] = React.useState<null | Location>(null);

  React.useEffect(() => {
    if (isMobile) {
      window.addEventListener('scroll', handleScroll);
    }
    //eslint-disable-next-line
  }, []);

  const handleScroll = event => {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;
    if (winScroll > 0) {
      setPosition('fixed');
    } else {
      setPosition('absolute');
    }
  };

  const { isAuthenticating } = useAuth();
  return !isAuthenticating ? (
    <Router>
      <Navigation />
      <Suspense
        fallback={
          <Loader
            currentLocation={l => {
              setLocation(l);
            }}
          />
        }
      >
        <Box minHeight="100vh">
          <Switch>
            {routes.map(route => {
              const RouteComponent = route.private ? ProtectedRoute : Route;
              return <RouteComponent key={route.path} {...route} />;
            })}
            <Redirect to={PATHS.home} />
          </Switch>
        </Box>
        <STFlex bg="navBackground">
          <Footer />
        </STFlex>
      </Suspense>
      {isMobile &&
        location &&
        location.pathname !== PATHS.home &&
        location.pathname !== PATHS.visaApplication && (
          <Box
            position={position === 'absolute' ? 'absolute' : 'fixed'}
            bottom={0}
            width="100%"
            bg="primary"
            borderTopRightRadius="20px"
            borderTopLeftRadius="20px"
            minHeight="70px"
            p={3}
          >
            <BaseText variant="whiteBold">
              Visa applications need more space, this experience is best suited
              for bigger screens.
            </BaseText>
          </Box>
        )}
    </Router>
  ) : null;
};

export default AppRoutes;
