import { useMutation, useQuery, BaseQueryOptions } from 'react-query';
import { decamelizeKeys, camelizeKeys } from 'humps';
import { firestore } from 'firebase/app';
import { v4 as uuidv4 } from 'uuid';

import { CoverLetterInputs, BankStatementInputs, UtilityDoc } from 'src/types';
import { db, storage } from 'src/utils/firebase';
import {
  generatePDF,
  pushToDataLayer,
  setGlobalDataVariables,
} from 'src/utils/helpers';
import { getBankStateEmailTemplate } from 'src/pages/BankStatement/bankStatementEmailTemplate';
import {
  getBankStatementTemplate,
  getCoverLetterTemplate,
} from 'src/constants/documentTemplates';
import { DOC_TYPES, DOC_TYPES_LABELS } from 'src/constants';

type AddUtilityDocArgs = {
  data: object;
  docType: number;
  userId: string | null;
  email: string;
  sendMail?: Function;
};

async function addUtilityDoc({
  data,
  docType,
  userId,
  email,
  sendMail,
}: AddUtilityDocArgs) {
  let values = decamelizeKeys({
    docType,
    userId,
    email,
  });
  let url = '';
  const isBankStatement = docType === DOC_TYPES.bankStatementLetter;
  // If user is not logged in, then save the doc to storage to get the download url
  // so url can be attached to the email sent to un-authenticated user
  if (!userId) {
    const pdfData = isBankStatement
      ? getBankStatementTemplate(data as BankStatementInputs)
      : getCoverLetterTemplate(data as CoverLetterInputs);
    const file = await generatePDF(pdfData, false);
    const filename = `utility-docs/${email}/${uuidv4()}.pdf`;
    const ref = storage.ref().child(filename);
    const uploadTask = await ref.put(file as Blob);

    url = await uploadTask.ref.getDownloadURL();
  }

  await db
    .collection('utility_docs')
    .doc()
    .set({
      ...values,
      url,
      data: decamelizeKeys(data),
      created_at: firestore.FieldValue.serverTimestamp(),
    });

  if (!userId && sendMail) {
    pushToDataLayer({ Email_Me_Success: true });
    setGlobalDataVariables(
      isBankStatement
        ? 'Bank_Statement_Email_Success'
        : 'Cover_Letter_Email_Success'
    );
    sendMail({
      to: email,
      subject: DOC_TYPES_LABELS[docType],
      html: getBankStateEmailTemplate(url),
    });
  }
}

function useAddUtilityDoc() {
  return useMutation(addUtilityDoc);
}

async function getUtilityDocsByUser(_, { email }) {
  const utilityDocsSnapshot = await db
    .collection('utility_docs')
    .where('email', '==', email)
    .get();

  const data: UtilityDoc[] = [];

  for (let i = 0; i < utilityDocsSnapshot.docs.length; i++) {
    const doc = utilityDocsSnapshot.docs[i];
    data.push(
      camelizeKeys({
        ...doc.data(),
        createdAt: new Date(doc.data().created_at.seconds * 1000),
      }) as UtilityDoc
    );
  }
  return data;
}

function useGetUtilityDocsByUser(email, options?: BaseQueryOptions) {
  return useQuery(['utility-docs', { email }], getUtilityDocsByUser, {
    retry: 0,
    refetchOnWindowFocus: false,
    ...options,
  });
}

export { useAddUtilityDoc, useGetUtilityDocsByUser };
