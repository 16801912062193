import {
  useMutation,
  useQuery,
  BaseQueryOptions,
  queryCache,
} from 'react-query';
import { camelizeKeys, decamelizeKeys } from 'humps';

import { db } from 'src/utils/firebase';
import {
  VisaApplication,
  OkToBoard,
  PaymentDetails,
  Requirement,
} from 'src/types';
import { VISA_APPLICATION_STATUS } from 'src/constants';

type VisaApplicationArgs = {
  visaApplicationId?: string | null;
};

type AddVisaApplicationArgs = {
  userId: string;
  requirements: Requirement[];
  travelerId: string;
};

type VisaApplicationSubmitResponse = {
  submitted?: boolean;
};

type VisaApplicationSubmitArgs = {
  userId?: string | null;
  visaApplicationId: string;
  visaFees?: number | null;
  visaFeesCurrency?: string | null;
  submitApplication: boolean;
  okToBoard?: OkToBoard;
  paymentDetails?: PaymentDetails;
};

type UpdateVisaRequirement = {
  userId?: string | null;
  visaApplicationDocId?: string;
  data: any;
  requirement: Requirement;
};

export const addVisaApplication = async ({
  userId,
  requirements,
  travelerId,
}: AddVisaApplicationArgs) => {
  const visaRequirements = requirements!.reduce((acc, val) => {
    if (!val.deleted) {
      acc[val.id!] = val.data || null;
    }
    return acc;
  }, {});

  const visaApplication: VisaApplication = {
    managedBy: userId!,
    travelerId,
    isCompleted: false,
  };

  const res = decamelizeKeys(visaApplication);

  const docRef = await db
    .collection('visa_applications')
    .add({ ...res, visa_requirements: visaRequirements });

  if (docRef) {
    return { id: docRef.id };
  } else {
    return null;
  }
};

const useAddVisaApplication = () => {
  return useMutation(addVisaApplication, {
    onSettled: () => {
      queryCache.invalidateQueries(['visa-application']);
    },
  });
};

const getVisaApplication = async (
  _,
  { visaApplicationId }
): Promise<VisaApplication | null | any> => {
  const visaApplicationSnapshot = await db
    .collection('visa_applications')
    .where('application_id', '==', visaApplicationId)
    .get();

  const visaApplicationDoc = visaApplicationSnapshot.docs[0].data();
  // If doc doesn't exist, that means no visa application is in progress
  if (!visaApplicationDoc) {
    return null;
  }

  const ret = camelizeKeys({
    ...visaApplicationDoc,
    id: visaApplicationSnapshot.docs[0].id,
  });

  return ret;
};

const useGetVisaApplication = (
  { visaApplicationId }: VisaApplicationArgs,
  options: BaseQueryOptions
) => {
  return useQuery(
    [
      'visa-application',
      {
        visaApplicationId,
      },
    ],
    getVisaApplication,
    {
      refetchOnWindowFocus: false,
      ...options,
      cacheTime: 0,
    }
  );
};

const submitVisaApplication = async ({
  userId,
  visaApplicationId,
  submitApplication,
  okToBoard,
  paymentDetails,
}: VisaApplicationSubmitArgs): Promise<
  VisaApplicationSubmitResponse | undefined
> => {
  if (!userId || !visaApplicationId) {
    return;
  }

  const visaApplicationSnapshot = await db
    .collection('visa_applications')
    .doc(visaApplicationId)
    .get();

  if (visaApplicationSnapshot.exists) {
    await visaApplicationSnapshot.ref.update({
      ...(submitApplication && {
        application_status: VISA_APPLICATION_STATUS.completed,
      }),
      ...(okToBoard && {
        ok_to_board: decamelizeKeys(okToBoard),
      }),
      ...(paymentDetails && {
        payment_details: decamelizeKeys(paymentDetails),
      }),
    });
    return { submitted: true };
  }
  return { submitted: false };
};

const useSubmitVisaApplication = () => {
  return useMutation(submitVisaApplication, {
    onSettled: () => {
      queryCache.invalidateQueries(['visa-application']);
    },
  });
};

const getAllVisaApplications = async (
  _,
  { userId }
): Promise<VisaApplication[] | null> => {
  if (!userId) {
    return null;
  }

  const visaApplicationSnapshot = await db
    .collection('visa_applications')
    .where('user_id', '==', userId)
    .get();

  const visaApplications = visaApplicationSnapshot.docs.map(doc => {
    return camelizeKeys({ ...doc.data(), id: doc.id });
  });

  return visaApplications;
};

const updateVisaRequirement = async ({
  data,
  requirement,
  userId,
  visaApplicationDocId,
}: UpdateVisaRequirement) => {
  if (!userId || !visaApplicationDocId) {
    return;
  }

  const visaApplicationSnapshot = await db
    .collection('visa_applications')
    .doc(visaApplicationDocId)
    .get();
  if (visaApplicationSnapshot.exists) {
    await visaApplicationSnapshot.ref.update({
      [`visa_requirements.${requirement.id}`]: decamelizeKeys({ ...data }),
    });
  }
};

const useUpdateVisaRequirement = () => {
  return useMutation(updateVisaRequirement, {
    onSettled: () => {
      queryCache.invalidateQueries(['visa-application-group']);
    },
  });
};

const useGetAllVisaApplications = (userId: string | undefined | null) => {
  return useQuery(['visa-applications', { userId }], getAllVisaApplications, {
    refetchOnWindowFocus: false,
    retry: 0,
  });
};

export {
  useAddVisaApplication,
  useGetVisaApplication,
  useSubmitVisaApplication,
  useGetAllVisaApplications,
  useUpdateVisaRequirement,
};
