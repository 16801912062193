import { analytics } from 'src/utils/firebase';

export const logScreenViewEvent = (screenName: string, data?: any) => {
  analytics.logEvent('screen_view', {
    screen_name: screenName,
    ...data,
  });
};

export const logClickEvent = (
  screenName: string,
  clickedElement: string,
  data?: any
) => {
  analytics.logEvent('item_click', {
    screen_name: screenName,
    clicked_element: clickedElement,
    ...data,
  });
};

export const logSearchEvent = (
  screenName: string,
  searchElement: string,
  data?: any
) => {
  analytics.logEvent('search', {
    screen_name: screenName,
    search_element: searchElement,
    ...data,
  });
};

export const logLoginEvent = () => {
  analytics.logEvent('login', {
    method: 'Signin with google',
  });
};

export const logSetUserId = (id: string) => {
  analytics.setUserId(id);
};

export const logLogoutEvent = userId => {
  analytics.logEvent('logout', { user: userId });
};

type PurchaseEventData = {
  paymentMethod: string;
  currency: string;
  amount: number;
  applicationID: string;
  createdAt: Date | string;
  reference: string;
  tracker: string;
};

export const logPurchaseEvent = (
  screenName: string,
  data: PurchaseEventData
) => {
  const params = {
    screen_name: screenName,
    transaction_id: data.reference,
    currency: data.currency,
    value: data.amount,
    payment_type: data.paymentMethod,
    refrence: data.reference,
    tracker: data.tracker,
    created_at: data.createdAt,
  };
  analytics.logEvent('purchase', params);
};

export const logSetCheckoutOption = (
  screenName: string,
  paymentMethod: string,
  userId?: string | null
) => {
  analytics.logEvent('set_checkout_option', {
    user: userId,
    screen_name: screenName,
    checkout_option: paymentMethod,
  });
};
