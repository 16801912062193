/* eslint-disable no-useless-escape */
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { customAlphabet } from 'nanoid';

import { IMAGE_EXTENSIONS } from '../constants';
import { GlobalDataVariables } from 'src/types';

pdfMake.vfs = pdfFonts.pdfMake.vfs;
const characters = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';

export const redirectToPage = (
  push: any,
  redirectTo: string,
  params = null
) => {
  push({ pathname: redirectTo, search: params });
};

// Put the object into storage
export const setInLocalStorage = (name: string, value: string) =>
  localStorage && localStorage.setItem(name, value);

// Remove the object into storage
export const removeFromLocalStorage = (name: string) =>
  localStorage && localStorage.removeItem(name);

// Get the object from storage
export const getFromLocalStorage = (name: string) =>
  localStorage && localStorage.getItem(name);

export const parseLocalStorageData = (key: string) => {
  const data = getFromLocalStorage(key);
  const decryptedData = data;
  return decryptedData && JSON.parse(decryptedData);
};

// Capitalize first letter: karachi -> Karachi
export const capitalizeFirstLetter = str => {
  return str.charAt(0).toUpperCase() + str.toLowerCase().slice(1);
};

export const getFileExtension = file => {
  return file.name.split('.').slice(-1)[0].toLowerCase();
};

export const isFileImage = file => {
  const extension = getFileExtension(file);

  return IMAGE_EXTENSIONS.includes(extension);
};

export const isFilePDF = file => getFileExtension(file) === 'pdf';

export const isEmail = email => {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

// Generates pdf with a template
// Download PDF to client browser if user is authenticated
// Returns a blob is user is not authenticated
export const generatePDF = (template, isAuthenticated, fileName?) => {
  const ref = pdfMake.createPdf({
    content: [template],
  });
  if (isAuthenticated) {
    fileName ? ref.download(`${fileName}.pdf`) : ref.download();
    return;
  }
  return new Promise(resolve => {
    ref.getBlob(blob => {
      resolve(blob);
    });
  });
};

//bytes to mega bytes
export const bytesToMegaBytes = bytes =>
  Number((bytes / (1024 * 1024)).toFixed(4));

//function to convert phone number into international format
//e.g 0315-9922991 -> +923159922991
export const getPhoneNumberInInternationalFormat = phoneNumber =>
  phoneNumber ? '+92' + phoneNumber.substr(1).replace('-', '') : null;

export const getPhoneNumberInLocalFormat = phoneNumber =>
  phoneNumber
    ? phoneNumber.replace('+92', '0').slice(0, 4) + '-' + phoneNumber.slice(6)
    : null;

export const appendToURL = str => {
  let url = window.location.href;
  url += `${url.charAt(url.length - 1) === '/' ? '' : '/'}${str}`;
  updateURL(url);
};

export const updateURL = url => {
  window.history.pushState({ path: url }, '', url);
};

// Returns display id in the form of XXXX-XXXX
export const generateDisplayId = () => {
  let appId = customAlphabet(characters, 8)();
  appId = `${appId.substr(0, 4)}-${appId.substr(4)}`;
  return appId;
};

//return number of days from minutes
export const getDaysFromMinutes = (minutes: number) => {
  if (minutes === 0) {
    return 0;
  } else {
    return Math.round(minutes / (24 * 60));
  }
};

// Check if the requirement is completed or not by looking at data
export const isRequirementCompleted = ({ data, requirement }) => {
  if (Array.isArray(data)) {
    // If file limit is Infinity (indefinite amount of files can be uploaded), even uploading one file completes the requirement
    return requirement.fileLimit === Infinity
      ? data.length > 0
      : data.length === requirement.fileLimit;
  }

  return Boolean(data);
};

export const pushToDataLayer = variableObj => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(variableObj);
};

export const setGlobalDataVariables = (
  variableName?: keyof GlobalDataVariables
) => {
  if (!variableName) {
    window.Cover_Letter_Email_Success = false;
    window.Bank_Statement_Email_Success = false;
    window.Signed_Up_Success = false;
    return;
  }

  window[variableName] = true;
};
