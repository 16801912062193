import React from 'react';
import { ThemeProvider } from 'styled-components';

import { AuthProvider } from './contexts/AuthContext';
import { NoticeModalProvider } from './contexts/NoticeModalContext';
import defaultTheme from 'design/theme';
import { STToastContainer } from 'design/atoms/Toast';

type Props = {
  children: React.ReactNode;
};

const AppProviders = ({ children }: Props) => {
  return (
    <AuthProvider>
      <NoticeModalProvider>
        <ThemeProvider theme={defaultTheme}>
          <STToastContainer />
          {children}
        </ThemeProvider>
      </NoticeModalProvider>
    </AuthProvider>
  );
};

export default AppProviders;
