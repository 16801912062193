import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';

import { STHeader } from 'design/organisms/Header';
import { STFlex } from 'design/atoms/Container';
import { STNavItem } from 'design/atoms/NavItem';
import {
  STNavbar,
  STNavItemsGroup,
  STNavCollapse,
  STNavbarToggler,
} from 'design/molecules/Navigation/';
import {
  STBodyText,
  STDisabledBodyText,
  STActiveLink,
} from 'design/atoms/Text';
import { PATHS } from 'src/routes/routesConfig';
import { useAuth } from 'src/AppProviders/contexts';
import AuthModal from '../AuthModal';
import { Box } from 'design/atoms/Box';
import { STDropdown } from 'design/atoms/Dropdown';
import { STIcon } from 'design/atoms/Icon';
import { UserInfo } from 'src/types';
import { STHR } from 'design/atoms/HR';
import logo from 'src/static/images/sasta-logo.svg';
import { STButton } from 'design/atoms/Button';
import { BaseTextAnchored } from 'design/atoms/Text';
import { logClickEvent } from 'src/services/analytics.service';
import { SCREEN_NAMES } from 'src/constants';

interface RouterLinkProps {
  path?: string;
  search?: string;
  children: React.ReactNode;
}

const RouterLink = ({ path, search, children }: RouterLinkProps) => {
  return (
    <Link
      style={{ textDecoration: 'none', cursor: 'pointer', display: 'contents' }}
      to={{ pathname: path, search }}
    >
      {children}
    </Link>
  );
};

// specifically added to give the circle around the icon. if google is not providing user image then we have to add this.
const StyledHeaderIcon = styled(STIcon)`
  border: 2px solid ${({ theme }) => theme.colors.primary};
  border-radius: 40px;
  width: 35px;
  height: 35px;
  ${({ theme }) => theme.mediaQueries.mobile} {
    width: 25px;
    height: 25px;
  }
`;

type DropDownProps = {
  isOpen: boolean;
  toggleDropdown: Function;
  trigger: React.ReactElement;
  dropdownMenu: React.ReactElement;
  rightAligned: boolean;
};

type DropDownMenuProps = {
  user: UserInfo | null;
  toggleAuthModal: Function;
  toggleDropdown: Function;
};

type UtilityDropdownProps = {
  toggleDropdown: Function;
};

const DropDown: React.FC<DropDownProps> = ({
  isOpen,
  toggleDropdown,
  trigger,
  dropdownMenu,
  rightAligned,
}) => {
  return (
    <STDropdown
      rightAligned={rightAligned}
      trigger={trigger}
      isOpen={isOpen}
      toggle={e => toggleDropdown(e)}
      p={0}
    >
      {dropdownMenu}
    </STDropdown>
  );
};

const DropDownMenu: React.FC<DropDownMenuProps> = ({
  user,
  toggleAuthModal,
  toggleDropdown,
}) => {
  const { userName, email } = user!;
  return (
    <>
      <STBodyText px={3} pt={2}>
        {userName}
      </STBodyText>
      <STDisabledBodyText px={3} py={1}>
        {email}
      </STDisabledBodyText>
      <STHR />
      <RouterLink path={PATHS.myApplications}>
        <STActiveLink
          as="div"
          p={3}
          onClick={() => {
            logClickEvent(SCREEN_NAMES.header, 'My Applications');
          }}
        >
          My Applications
        </STActiveLink>
      </RouterLink>
      <STHR />
      <RouterLink path={PATHS.myDocuments}>
        <STActiveLink
          as="div"
          p={3}
          onClick={() => {
            logClickEvent(SCREEN_NAMES.header, 'My Documents');
          }}
        >
          My Documents
        </STActiveLink>
      </RouterLink>
      <RouterLink path={PATHS.myTravelers}>
        <STActiveLink
          as="div"
          p={3}
          onClick={() => {
            logClickEvent(SCREEN_NAMES.header, 'My Travelers');
          }}
        >
          My Travelers
        </STActiveLink>
      </RouterLink>
      <STHR />
      <STActiveLink
        as="div"
        p={3}
        onClick={() => {
          logClickEvent(SCREEN_NAMES.header, 'Logout');
          toggleAuthModal();
        }}
      >
        Logout
      </STActiveLink>
    </>
  );
};

const UtilityDropdownMenu: React.FC<UtilityDropdownProps> = ({
  toggleDropdown,
}) => {
  return (
    <>
      <RouterLink path={`${PATHS.utility}/bank-statement-letter`}>
        <STActiveLink as="div" p={3}>
          Bank Statement Letter
        </STActiveLink>
      </RouterLink>
      <RouterLink path={`${PATHS.utility}/cover-letter`}>
        <STActiveLink as="div" p={3}>
          Cover Letter
        </STActiveLink>
      </RouterLink>
    </>
  );
};

const Navigation = props => {
  const { isUserLoggedIn, isAuthenticating, toggleAuthModal, user } = useAuth();
  const [isMyAccountDropDownOpen, setIsMyAccountDropDownOpen] = React.useState(
    false
  );
  const [isUtilityDropdownOpen, setIsUtilityDropdownOpen] = React.useState(
    false
  );
  const [isDrawerOpen, changeDrawerVisibility] = React.useState(false);
  const {
    location: { pathname },
  } = props;

  return (
    <>
      <STHeader>
        <STFlex
          justifyContent="space-between"
          flexDirection="row"
          alignItems="center"
          px={[3, 3]}
          width={['100%', 1140]}
        >
          <STNavbar>
            <STNavbarToggler onClick={() => changeDrawerVisibility(true)} />
            <a
              style={{ display: 'contents', textDecoration: 'none' }}
              href="https://sastaticket.pk/"
            >
              <Box
                width={['146px', '160px']}
                height={['30px', '32px']}
                mx={['auto', 0]}
                my="auto"
                left={0}
                right={0}
                as="img"
                src={logo}
                onClick={() => {}}
              />
            </a>
            <STNavCollapse
              isOpen={isDrawerOpen}
              onClose={() => changeDrawerVisibility(false)}
            >
              <STNavItemsGroup convertDrawer={true}>
                <Box ml={[0, 6]}>
                  <RouterLink path={PATHS.home}>
                    <STNavItem
                      active={
                        pathname.includes(PATHS.home) && pathname.length === 1
                      }
                      label="Home"
                      onClick={() => {
                        logClickEvent(SCREEN_NAMES.header, 'Home');
                      }}
                    />
                  </RouterLink>
                </Box>
                <Box ml={[0, 6]}>
                  <DropDown
                    rightAligned={false}
                    trigger={
                      <STNavItem
                        label="Utility"
                        active={pathname.includes(PATHS.utility)}
                        onClick={() => {
                          logClickEvent(SCREEN_NAMES.header, 'Utility');
                        }}
                      />
                    }
                    isOpen={isUtilityDropdownOpen}
                    toggleDropdown={value => {
                      setIsUtilityDropdownOpen(value);
                    }}
                    dropdownMenu={
                      <UtilityDropdownMenu
                        toggleDropdown={setIsUtilityDropdownOpen}
                      />
                    }
                  />
                </Box>
              </STNavItemsGroup>
            </STNavCollapse>
          </STNavbar>
          <STNavItemsGroup convertDrawer={true}>
            {!isAuthenticating && !isUserLoggedIn && (
              <STButton
                p={['5px', '10px']}
                height={['30px', '40px']}
                onClick={() => {
                  logClickEvent(SCREEN_NAMES.header, 'Login');
                  toggleAuthModal();
                }}
              >
                LOGIN
              </STButton>
            )}
            {!isAuthenticating && isUserLoggedIn && user && (
              <Box>
                <DropDown
                  rightAligned={true}
                  trigger={
                    user?.imageUrl ? (
                      <STFlex flexDirection="row" alignItems="center">
                        <Box
                          as="img"
                          borderRadius={'40px'}
                          width={'32px'}
                          height={'32px'}
                          src={user?.imageUrl}
                          mr={[0, 2]}
                        ></Box>
                        {!isMobile && (
                          <>
                            <BaseTextAnchored
                              variant="blueBoldWithoutHover"
                              mr={1}
                              width="90px"
                            >
                              My Account
                            </BaseTextAnchored>
                            <STFlex mt={1}>
                              <STIcon icon="ChevronDown" color="primary" />
                            </STFlex>
                          </>
                        )}
                      </STFlex>
                    ) : (
                      <StyledHeaderIcon
                        icon="User"
                        size={'md'}
                        color={'primary'}
                        smSize={'sm'}
                      />
                    )
                  }
                  isOpen={isMyAccountDropDownOpen}
                  toggleDropdown={value => {
                    setIsMyAccountDropDownOpen(value);
                  }}
                  dropdownMenu={
                    user && (
                      <DropDownMenu
                        user={user}
                        toggleAuthModal={toggleAuthModal}
                        toggleDropdown={setIsMyAccountDropDownOpen}
                      />
                    )
                  }
                />
              </Box>
            )}
          </STNavItemsGroup>
        </STFlex>
      </STHeader>
      <AuthModal />
    </>
  );
};

export default withRouter(Navigation);
