import React from 'react';
import Modal from 'styled-react-modal';
import { Box } from 'design/atoms/Box';
import { STH2 } from 'design/atoms/Text';
import { STButton } from 'design/atoms/Button';
import { STIcon } from 'design/atoms/Icon';
import { STFlex } from 'design/atoms/Container';

export const STModal = Modal.styled`
  width: ${props => (props.width ? props.width : '540')}px;
  min-height: 200px;
  padding: ${props => props.theme.space[3]}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.theme.colors.white};`;

export const STDialog = ({
  isOpen,
  toggleModal,
  icon,
  iconColor = 'primary',
  title,
  primaryButtonLabel,
  primaryButtonAction,
  secondaryButtonLabel,
  secondaryButtonAction,
}) => {
  const toggleModalState = () => {
    toggleModal(!isOpen);
  };
  return (
    <STModal
      isOpen={isOpen}
      onBackgroundClick={toggleModalState}
      onEscapeKeydown={toggleModalState}
    >
      {icon ? (
        <Box color={iconColor} display={['none', 'block']}>
          <STIcon icon={icon} size="xl" mb={3} />
        </Box>
      ) : null}
      <STH2 fontWeight="400" textAlign="center" mb={4}>
        {title}
      </STH2>
      <STFlex flexDirection="row" width={['100%', '50%']}>
        <STButton variant="secondary" onClick={secondaryButtonAction}>
          {secondaryButtonLabel}
        </STButton>
        <STButton ml={4} onClick={primaryButtonAction}>
          {primaryButtonLabel}
        </STButton>
      </STFlex>
    </STModal>
  );
};
