import React from 'react';

const Home = React.lazy(() => import('../pages/Home'));
const BankStatement = React.lazy(() => import('../pages/BankStatement'));
const MyDocuments = React.lazy(() => import('../pages/MyDocuments'));
const VisaDetails = React.lazy(() => import('../pages/VisaDetails'));
const VisaDocumentsUpload = React.lazy(() =>
  import('../pages/VisaDocumentsUpload')
);

const MyTravelers = React.lazy(() => import('../pages/MyTravelers'));
const VisaApplicationReview = React.lazy(() =>
  import('../pages/VisaApplicationReview')
);
const VisaApplicationSuccess = React.lazy(() =>
  import('../pages/VisaApplicationSuccess')
);
const VisaApplicationGroupsListing = React.lazy(() =>
  import('../pages/VisaApplicationGroupsListing')
);
const CoverLetter = React.lazy(() => import('../pages/CoverLetter'));
const TermsAndConditions = React.lazy(() =>
  import('../pages/TermsAndConditions')
);

export const PATHS = {
  home: '/',
  utility: '/utility',
  visaApplication: '/search',
  myApplications: '/my-applications',
  myDocuments: '/my-documents',
  visaDocumentsUpload: '/apply',
  myTravelers: '/my-travelers',
  termsAndConditions: '/terms-and-conditions',
};

export default [
  {
    path: PATHS.home,
    component: Home,
    private: false,
    exact: true,
  },
  {
    path: `${PATHS.utility}/bank-statement-letter`,
    component: BankStatement,
    private: false,
  },
  {
    path: PATHS.myDocuments,
    component: MyDocuments,
    private: true,
  },
  {
    path: PATHS.visaApplication,
    component: VisaDetails,
    private: false,
  },
  {
    path: `${PATHS.visaDocumentsUpload}/:countryCode/:visaApplicationDisplayId/traveler/:travelerIndex?`,
    component: VisaDocumentsUpload,
    private: true,
  },
  {
    path: `${PATHS.myTravelers}`,
    component: MyTravelers,
    private: true,
  },
  {
    path: `${PATHS.visaDocumentsUpload}/:countryCode/:visaApplicationDisplayId/review`,
    component: VisaApplicationReview,
    private: true,
  },
  {
    path: `${PATHS.visaDocumentsUpload}/:countryCode/:visaApplicationDisplayId/success`,
    component: VisaApplicationSuccess,
    private: true,
  },
  {
    path: PATHS.myApplications,
    component: VisaApplicationGroupsListing,
    private: true,
  },
  {
    path: `${PATHS.utility}/cover-letter`,
    component: CoverLetter,
    private: false,
  },
  {
    path: PATHS.termsAndConditions,
    component: TermsAndConditions,
    private: false,
  },
];
