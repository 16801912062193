import styled, { css } from 'styled-components';
import { Box } from '../../../atoms/Box';

export const CollapseStyled = styled.div(
  props => css`
    @keyframes slide-left-to-right {
      from {
        left: -100vw;
      }
      to {
        left: 0;
      }
    }

    display: flex;
    flex-direction: row;
    width: 100%;
    ${props.theme.mediaQueries.mobile} {
      flex-direction: column;
      height: 100vh;
      width: 70vw;
      top: 0;
      left: 0;
      z-index: 21;
      transition: width 2s;
      justify-content: flex-start;
      animation: slide-left-to-right 0.5s;
      display: ${props['isOpen'] ? 'flex' : 'none'};
      position: fixed;
      background-color: ${props.theme.colors.white};
    }
  `
);

export const Overlay = styled.div(
  props => css`
    height: 100vh;
    display: none;
    width: 100vw;
    z-index: 20;
    background: rgba(0, 0, 0, 0.7);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    ${props.theme.mediaQueries.mobile} {
      display: flex;
    }
  `
);

export const CloseButtonContainer = styled(Box)(
  props => css`
    border-bottom: ${props.theme.borders[0]};
    width: auto;
  `
);
