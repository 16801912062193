import React from 'react';
import { NavItemsGroupStyled } from './NavItemsGroup.style';

interface NavItemsGroupProps {
  children: React.ReactNode | React.ReactNode[];
  align?: 'left' | 'right';
  convertDrawer?: boolean;
}

export const STNavItemsGroup = ({
  children,
  align = 'left',
}: NavItemsGroupProps) => {
  return <NavItemsGroupStyled align={align}>{children}</NavItemsGroupStyled>;
};
