import React from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';

import { BaseText } from 'design/atoms/Text';
import { STFlex } from 'design/atoms/Container';
import { STInputField } from 'design/molecules/InputField';
import { STButton } from 'design/atoms/Button';
import { EMAIL_REGEX, SCREEN_NAMES } from 'src/constants';
import { useAddSubscriber } from 'src/hooks/api';
import { toast } from 'react-toastify';
import { STSuccessToast } from 'design/atoms/Toast';
import { useLoader } from 'src/hooks';
import logo from 'src/static/images/sasta-logo.svg';
import wIcon from 'src/static/images/whatsapp-icon.svg';
import fIcon from 'src/static/images/facebook.svg';
import tIcon from 'src/static/images/twitter.svg';
import lIcon from 'src/static/images/linkedin.svg';
import phone from 'src/static/images/phone.svg';
import { Box } from 'design/atoms/Box';
import { logClickEvent } from 'src/services/analytics.service';

const StyledAnchor = styled.a`
  text-decoration: none;
`;

type EmailSubscriptionInputs = {
  email: string;
};

const emailSubscriptionDefaultValues = {
  email: '',
};

const Footer: React.FC = () => {
  const { register, handleSubmit, errors, setValue, reset, watch } = useForm<
    EmailSubscriptionInputs
  >({
    defaultValues: emailSubscriptionDefaultValues,
  });
  const [
    addSubscriber,
    { data: addSubscriberResponse, isLoading, isSuccess },
  ] = useAddSubscriber();
  const email = watch('email');

  React.useEffect(() => {
    if (isSuccess) {
      if (addSubscriberResponse?.subscriberAlreadyExist) {
        toast.success(
          <STSuccessToast message="You are already a subscriber!" />
        );
      } else {
        toast.success(
          <STSuccessToast message="You have subscribed successfully!" />
        );
      }
    }
  }, [isSuccess, addSubscriberResponse]);

  const handleSubscription = async data => {
    if (data) {
      await addSubscriber({
        email: data.email,
      });
      setValue('email', '');
      reset(emailSubscriptionDefaultValues);
    }
  };

  useLoader({
    startCondition: isLoading,
    doneCondition: !isLoading,
  });

  React.useEffect(() => {
    register({ name: 'email' }, { required: true, pattern: EMAIL_REGEX });
    // eslint-disable-next-line
  }, []);

  return (
    <Box mx="auto" py={4} px={3} width={['100%', 990, 1124]}>
      <STFlex flexDirection={['column', 'row']} justifyContent="space-between">
        <STFlex flexDirection="column" width={['100', '60%']}>
          <STFlex variant="blackBold" mt={3} ml={[2, 0]}>
            <img src={logo} alt="Sastaticket.pk" />
          </STFlex>
        </STFlex>
        <STFlex
          flexDirection="column"
          width={['100%', '40%']}
          mt={[4, 0]}
          position="relative"
          top={['0px', '35px']}
          mr={[0, 5]}
        >
          <BaseText variant="blueBoldClean" fontSize={3} mb={3}>
            Get discounted offers and tips by email
          </BaseText>
          <form
            onSubmit={handleSubmit(handleSubscription)}
            style={{ width: '100%' }}
          >
            <STInputField
              name="email"
              placeholder="Your email address"
              onChange={e => {
                setValue('email', e.target.value);
              }}
              value={email}
              error={Boolean(errors.email)}
              mb={0}
            />
            <STFlex flexDirection="row" justifyContent="flex-end">
              <STButton
                type="submit"
                width="120px"
                disabled={isLoading}
                variant={isLoading ? 'disabled' : 'secondary'}
                position="relative"
                bottom="20px"
                onClick={() => {
                  logClickEvent(SCREEN_NAMES.footer, 'Subscribe Button Click');
                }}
              >
                Subscribe
              </STButton>
            </STFlex>
          </form>
        </STFlex>
      </STFlex>
      <STFlex flexDirection="column" justifyContent="space-between">
        <STFlex
          width={['100%', '34%']}
          position="relative"
          bottom={['0px', '75px']}
          flexDirection="column"
          mt={[3, 0]}
        >
          <BaseText style={{ fontSize: '18px' }} variant="blueBoldWithoutHover">
            Need Help?
          </BaseText>
          <STFlex
            flexDirection="row"
            justifyContent={['space-between', 'flex-start']}
            alignItems="center"
            mt={[2, 1]}
          >
            <STFlex flexDirection="row" mr={[6, 3]} width="100%">
              <STFlex
                alignItems="center"
                width="60%"
                onClick={() => {
                  logClickEvent(SCREEN_NAMES.footer, 'Call to Sasta ticket');
                }}
              >
                <STFlex mr={2}>
                  <StyledAnchor href="tel:(021) 111 172 782">
                    <img
                      src={phone}
                      height="15"
                      width="12"
                      alt="Call to Sastaticket"
                    />
                  </StyledAnchor>
                </STFlex>
                <StyledAnchor href="tel:(021) 111 172 782">
                  <BaseText variant="greyMedium">(021) 111 172 782</BaseText>
                </StyledAnchor>
              </STFlex>

              <STFlex
                flexDirection="row"
                justifyContent="flex-end"
                alignItems="center"
                width="40%"
                position="relative"
                right={['0px', '50px']}
                onClick={() => {
                  logClickEvent(
                    SCREEN_NAMES.footer,
                    'Whatssapp - Chat with us'
                  );
                }}
              >
                <STFlex mr={2}>
                  <StyledAnchor
                    href="https://wa.me/923201172782"
                    target="_blank"
                  >
                    <img src={wIcon} height="22" width="22" alt="Whatssapp" />
                  </StyledAnchor>
                </STFlex>
                <StyledAnchor href="https://wa.me/923201172782" target="_blank">
                  <BaseText variant="greyMedium">Chat with us</BaseText>
                </StyledAnchor>
              </STFlex>
            </STFlex>
          </STFlex>

          <STFlex
            flexDirection="row"
            justifyContent={['center', 'flex-start']}
            alignItems="center"
            mt={[4, 5]}
          >
            <Box
              mr={3}
              onClick={() => {
                logClickEvent(
                  SCREEN_NAMES.footer,
                  'Facebook Icon - Redirected to Facebook'
                );
              }}
            >
              <StyledAnchor
                href="https://www.facebook.com/sastaticket.pk"
                target="_blank"
              >
                <img src={fIcon} height="25" width="25" alt="Facebook" />
              </StyledAnchor>
            </Box>
            <Box
              mr={3}
              onClick={() => {
                logClickEvent(
                  SCREEN_NAMES.footer,
                  'Twitter Icon - Redirected to Twitter'
                );
              }}
            >
              <StyledAnchor
                href="https://twitter.com/sastaticketpk"
                target="_blank"
              >
                <img src={tIcon} height="25" width="25" alt="Twitter" />
              </StyledAnchor>
            </Box>
            <Box
              mr={3}
              onClick={() => {
                logClickEvent(
                  SCREEN_NAMES.footer,
                  'Linkedin Icon - Redirected to Linkedin'
                );
              }}
            >
              <StyledAnchor
                href="https://www.linkedin.com/company/sastaticket.pk/"
                target="_blank"
              >
                <img src={lIcon} height="25" width="25" alt="Linkedin" />
              </StyledAnchor>
            </Box>
          </STFlex>
        </STFlex>
      </STFlex>
    </Box>
  );
};

export default Footer;
