import { keyframes } from 'styled-components';

const colors = {
  black: '#000',
  grey70: '#484848',
  grey80: '#757575',
  grey40: '#919191',
  grey20: '#c7c7c7',
  disabled: '#ececec',
  background: '#F4F5F7',
  white: '#FFF',
  navBackground: '#f7f7f7',
  primaryDark: '#00598b',
  lavender: '#e8edf2',

  primary: '#006FAE',
  primaryHover: '#00598B',
  secondary: '#f5ac2f',
  secondaryHover: '#f09905',

  success: '#00BB14',
  error: '#E42526',
  errorHover: '#A5221F',
  warning: '#FFC107',

  facebook: '#3B5998',
  google: '#EA4335',

  primaryLight: 'rgba(0,111,174,0.3)',
  errorLight: 'rgba(208,2,27,0.3)',
};

const space = {
  0: 0,
  1: 5,
  2: 10,
  3: 15,
  4: 30,
  5: 45,
  6: 60,
  7: 120,
  8: 240,
  9: 480,
};

space['small'] = space[2];
space['medium'] = space[3];
space['large'] = space[4];

const fontSizes = { 0: 13, 1: 15, 2: 17, 3: 20, 4: 24, 5: 32 };

// desktop only?
fontSizes['body'] = fontSizes[1];
fontSizes['h3'] = fontSizes[2];
fontSizes['h2'] = fontSizes[3];
fontSizes['h1'] = fontSizes[4];

const fonts = {
  primary: '"Inter", sans-serif',
  secondary: '"Product Sans", sans-serif',
};

const weights = {
  light: '300',
  regular: '400',
  medium: '500',
  semiBold: '600',
  bold: '700',
  extrabold: '800',
  black: '900',
};

const icons = {
  xs: 18,
  sm: 22,
  md: 32,
  lg: 50,
  xl: 60,
};

const radii = [0, 4, 21.5];

const borders = [
  `1px solid ${colors.grey20}`,
  `1px solid ${colors.primary}`,
  `1px solid ${colors.primaryHover}`,
  `1px solid ${colors.error}`,
  `1px solid ${colors.errorHover}`,
  `1px solid ${colors.disabled}`,
  `3px solid ${colors.success}`,
  `3px solid ${colors.secondary}`,
];

const shadows = {
  0: '0 2px 4px 0 rgba(0, 0, 0, 0.1)',
  1: '0 0 3px 0 rgba(0, 118, 222, 0.50)',
  2: '0 0 3px 0 rgba(228, 37, 38, 0.50)',
  3: '0 20px 20px 0 rgba(0,0,0,0.07)',
};

shadows['dropshadow'] = shadows[0];
shadows['focusfield'] = shadows[1];

const breakpoints = ['576px', '1140px'];

const mediaQueries = {
  mobile: `@media screen and (max-width: ${breakpoints[0]})`,
  desktop: `@media screen and (min-width: ${breakpoints[1]})`,
};

const animations = {
  fadeIn: keyframes`
    0% { opacity: 0;}
    100% { opacity: 1; }
  `,
};

export default {
  name: 'Asaan Visa',
  colors,
  space,
  fontSizes,
  mediaQueries,
  fonts,
  weights,
  icons,
  radii,
  breakpoints,
  borders,
  shadows,
  animations,
};
