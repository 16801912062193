import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  *,
  *::before,
  *::after {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
  }

  html {
    font-size: ${props => props.theme.fontSizes.body}px;
  }

  body {
    box-sizing: border-box;
    font-weight: ${props => props.theme.weights.regular};
    overflow-y : auto;
  }

  #nprogress .bar {
    height: 3px;
  }

  #nprogress .spinner-icon {
    width: 32px;
    height: 32px;
    border: solid 3px transparent;
    box-sizing: border-box;

    border-top-color: #29d;
    border-left-color: #29d;
    border-radius: 50%;

    -webkit-animation: nprogress-spinner 400ms linear infinite;
            animation: nprogress-spinner 400ms linear infinite;
  }
`;
