import {
  useMutation,
  useQuery,
  BaseQueryOptions,
  queryCache,
} from 'react-query';
import { camelizeKeys, decamelizeKeys } from 'humps';

import firebase, { db } from 'src/utils/firebase';
import { generateDisplayId } from 'src/utils/helpers';
import {
  OkToBoard,
  PaymentDetails,
  Visa,
  VisaApplication,
  VisaApplicationGroup,
  VisaDurationOption,
} from 'src/types';
import { VISA_APPLICATION_STATUS } from 'src/constants';
import { getTraveler } from './travelers';
import { addVisaApplication } from './visaApplications';
import { firestore } from 'firebase/app';

type GetVisaApplicationGroupArgs = {
  managedBy?: string | null;
  visaApplicationGroupDisplayId?: string;
  countryId?: string;
  status?: number;
  limit?: number;
  orderBy?: string;
};

type CreateVisaApplicationGroupArgs = {
  managedBy: string;
  countryId: string;
  departureDate: Date;
  returnDate: Date;
  visaType: number;
  visaDuration: VisaDurationOption;
  travelersCount: number;
  visa: Visa;
};

type VisaApplicationGroupSubmitArgs = {
  country: string;
  managedBy: string | null;
  visaApplicationGroupId: string;
  okToBoard?: OkToBoard;
  paymentDetails?: PaymentDetails;
  visaApplicationGroup: VisaApplicationGroup;
  travelInsurance: string;
  flightTickets: string;
  vendorId: string;
  userContactNo?: string | null;
  userEmail: string | null;
  userName: string | null;
};

type AddVisaApplicationToGroupArgs = {
  managedBy?: string;
  displayId?: string;
  visa: Visa;
};

const COLLECTION_NAME = 'visa_applications_groups';

const getVisaApplicationsGroups = async (
  _,
  {
    managedBy,
    visaApplicationGroupDisplayId,
    countryId,
    status,
    limit,
    orderBy,
  }: GetVisaApplicationGroupArgs
) => {
  if (!managedBy) return;
  let visaApplicationsGroupsQuery = db
    .collection(COLLECTION_NAME)
    .where('managed_by', '==', managedBy)
    .where('deleted', '==', false);

  if (countryId) {
    visaApplicationsGroupsQuery = visaApplicationsGroupsQuery.where(
      'country_id',
      '==',
      countryId
    );
  }

  if (status !== undefined) {
    visaApplicationsGroupsQuery = visaApplicationsGroupsQuery.where(
      'status',
      '==',
      status
    );
  }

  if (visaApplicationGroupDisplayId) {
    visaApplicationsGroupsQuery = visaApplicationsGroupsQuery.where(
      'display_id',
      '==',
      visaApplicationGroupDisplayId
    );
  }

  if (orderBy) {
    visaApplicationsGroupsQuery = visaApplicationsGroupsQuery.orderBy(orderBy);
  }

  if (limit) {
    visaApplicationsGroupsQuery = visaApplicationsGroupsQuery.limit(limit);
  }

  const visaApplicationsGroupsSnapshot = await visaApplicationsGroupsQuery.get();

  let ret: VisaApplicationGroup[] = [];

  // If there are visa application groups in progress
  if (visaApplicationsGroupsSnapshot.docs.length) {
    for (let i = 0; i < visaApplicationsGroupsSnapshot.docs.length; i++) {
      const doc = visaApplicationsGroupsSnapshot.docs[i];
      const visaApplications: VisaApplication[] = [];
      const visaApplicationsSnapshot = doc.data().visa_applications;

      // For each group fetch it's visa applications data
      for (let j = 0; j < visaApplicationsSnapshot.length; j++) {
        const visaApplicationData = (
          await visaApplicationsSnapshot[j].get()
        ).data();

        // For each visa application fetch it's traveler data
        // If visa application group is newly created and traveler is not selected
        // for this visa application, then traveler_id will be empty string
        const traveler = visaApplicationData?.traveler_id
          ? await getTraveler('', {
              travelerId: visaApplicationData.traveler_id,
            })
          : null;
        visaApplications.push({
          id: visaApplicationsSnapshot[j].id,
          ...visaApplicationData,
          traveler,
        });
      }

      // Omit visa_applications since it's a reference and camelize goes into infinite loop if it's not excluded
      const { visa_applications, ...data } = doc.data();
      const camelizeVisaApplicationGroupData = camelizeKeys({
        ...data,
        id: doc.id,
        visaApplications,
      });

      const visaApplicationData = visaApplications.map((vA: any) => {
        const requirementsObj = {};

        Object.keys(vA.visa_requirements).forEach(reqId => {
          requirementsObj[reqId] = camelizeKeys(vA.visa_requirements[reqId]);
        });

        return {
          ...camelizeKeys(vA),
          visaRequirements: requirementsObj,
        };
      });

      ret.push({
        ...camelizeVisaApplicationGroupData,
        visaApplications: visaApplicationData,
      });
    }
  }
  return ret;
};

const useGetVisaApplicationsGroups = (
  {
    managedBy,
    visaApplicationGroupDisplayId,
    countryId,
    status,
    limit,
    orderBy,
  }: GetVisaApplicationGroupArgs,
  options?: BaseQueryOptions
) => {
  return useQuery(
    [
      'visa-application-group',
      {
        managedBy,
        visaApplicationGroupDisplayId,
        countryId,
        status,
        limit,
        orderBy,
      },
    ],
    getVisaApplicationsGroups,
    {
      refetchOnWindowFocus: false,
      ...options,
    }
  );
};

const createVisaApplicationGroup = async ({
  managedBy,
  countryId,
  departureDate,
  returnDate,
  visaType,
  visaDuration,
  travelersCount,
  visa,
}: CreateVisaApplicationGroupArgs) => {
  if (!managedBy) return null;

  const visaApplicationsRefs: any = [];

  for (let i = 0; i < travelersCount; i++) {
    const res = await addVisaApplication({
      userId: managedBy,
      requirements: visa.requirements,
      travelerId: '',
    });
    if (res) {
      visaApplicationsRefs.push(await db.doc(`visa_applications/${res.id}`));
    }
  }

  const displayId = generateDisplayId();
  const visaApplicationGroupData = {
    visaApplications: [],
    status: VISA_APPLICATION_STATUS.inProgress,
    deleted: false,
    displayId,
    managedBy,
    countryId,
    departureDate,
    returnDate,
    visaType,
    visaDuration,
  };

  const ref = await db.collection(COLLECTION_NAME).add({
    ...decamelizeKeys(visaApplicationGroupData),
    visa_applications: visaApplicationsRefs,
    created_at: firebase.firestore.FieldValue.serverTimestamp(),
  });

  return {
    id: ref.id,
    displayId,
  };
};

const useCreateVisaApplicationGroup = () => {
  return useMutation(createVisaApplicationGroup);
};

const deleteVisaApplicationGroup = async displayId => {
  const groupSnapshot = await db
    .collection(COLLECTION_NAME)
    .where('display_id', '==', displayId)
    .get();

  if (groupSnapshot.docs.length) {
    await groupSnapshot.docs[0].ref.update({
      deleted: true,
    });
  }
};

const useDeleteVisaApplicationGroup = () => {
  return useMutation(deleteVisaApplicationGroup, {
    onSettled: () => {
      queryCache.invalidateQueries(['visa-application-group']);
    },
  });
};

const submitVisaApplicationGroup = async ({
  managedBy,
  visaApplicationGroupId,
  okToBoard,
  paymentDetails,
  visaApplicationGroup,
  travelInsurance,
  flightTickets,
  vendorId,
  userContactNo,
  userEmail,
  userName,
  country,
}: VisaApplicationGroupSubmitArgs) => {
  if (!managedBy || !visaApplicationGroupId) {
    return;
  }

  const visaApplicationGroupSnapshot = await db
    .collection(COLLECTION_NAME)
    .where('managed_by', '==', managedBy)
    .where('display_id', '==', visaApplicationGroupId)
    .get();

  if (!visaApplicationGroupSnapshot.empty) {
    //writing batch writes for atomic operations
    const batch = db.batch();

    const completedApplicationRef = db
      .collection('completed_applications')
      .doc();

    batch.update(visaApplicationGroupSnapshot.docs[0].ref, {
      status: VISA_APPLICATION_STATUS.completed,
      ...(okToBoard && {
        ok_to_board: decamelizeKeys(okToBoard),
      }),
      ...(paymentDetails && {
        payment_details: decamelizeKeys(paymentDetails),
      }),
      travel_insurance: travelInsurance,
      flight_tickets: flightTickets,
      user_email: userEmail,
      user_contact_no: userContactNo,
    });

    batch.set(completedApplicationRef, {
      id: completedApplicationRef.id,
      visa_application_group: {
        ...visaApplicationGroup,
        payment_details: decamelizeKeys(paymentDetails),
        status: VISA_APPLICATION_STATUS.completed,
      },
      managed_by: managedBy,
      vendor_id: vendorId,
      created_at: firestore.FieldValue.serverTimestamp(),
      updated_at: firestore.FieldValue.serverTimestamp(),
      status: 'PENDING',
      payment_method: paymentDetails?.paymentMethod,
      user_email: userEmail,
      user_name: userName,
      country,
    });

    // Commit the batch
    await batch.commit();
  }
};

const useSubmitVisaApplicationGroup = () => {
  return useMutation(submitVisaApplicationGroup);
};

const addVisaApplicationToGroup = async ({
  displayId,
  managedBy,
  visa,
}: AddVisaApplicationToGroupArgs) => {
  const groupSnapshot = await db
    .collection(COLLECTION_NAME)
    .where('display_id', '==', displayId)
    .get();

  if (!groupSnapshot.docs.length) {
    throw new Error(
      `No visa application group exist with display id ${displayId}`
    );
  }

  const visaApplicationRef = await addVisaApplication({
    userId: managedBy!,
    requirements: visa.requirements,
    travelerId: '',
  });

  if (visaApplicationRef) {
    await groupSnapshot.docs[0].ref.update({
      visa_applications: firebase.firestore.FieldValue.arrayUnion(
        await db.doc(`visa_applications/${visaApplicationRef.id}`)
      ),
    });
  }
};

const useAddVisaApplicationToGroup = () => {
  return useMutation(addVisaApplicationToGroup, {
    onSettled: () => {
      queryCache.invalidateQueries('visa-application-group');
    },
  });
};

const deleteVisaApplicationFromGroup = async ({ displayId, index }) => {
  const groupSnapshot = await db
    .collection(COLLECTION_NAME)
    .where('display_id', '==', displayId)
    .get();

  if (!groupSnapshot.docs.length) {
    throw new Error(
      `No visa application group exist with display id ${displayId}`
    );
  }

  const group = groupSnapshot.docs[0].data();
  const applications = group.visa_applications;
  applications.splice(index, 1);
  await groupSnapshot.docs[0].ref.update({
    visa_applications: applications,
  });
};

const useDeleteVisaApplicationFromGroup = ({ onSettled }) => {
  return useMutation(deleteVisaApplicationFromGroup, {
    onSettled: () => {
      queryCache.invalidateQueries('visa-application-group');
      onSettled();
    },
  });
};

export {
  useCreateVisaApplicationGroup,
  useGetVisaApplicationsGroups,
  useSubmitVisaApplicationGroup,
  useDeleteVisaApplicationGroup,
  useAddVisaApplicationToGroup,
  useDeleteVisaApplicationFromGroup,
};
