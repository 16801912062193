import React from 'react';
import { ModalProvider } from 'styled-react-modal';

import { STModal } from 'design/molecules/Modal/Modal';
import { STButton } from 'design/atoms/Button';
import { STH2, BaseText } from 'design/atoms/Text';
import { STFlex } from 'design/atoms/Container';
import gIcon from 'src/static/images/google-icon.svg';
import { signInWithGoogle, signOut } from 'src/services/auth.service';
import { useAuth } from 'src/AppProviders/contexts';
import { Box } from 'design/atoms/Box';
import { logLoginEvent, logLogoutEvent } from 'src/services/analytics.service';

const AuthModal: React.FC = () => {
  const {
    isModalOpen,
    toggleAuthModal,
    isUserLoggedIn,
    isAuthenticating,
    user,
  } = useAuth();

  return (
    <ModalProvider>
      <STModal
        isOpen={isModalOpen}
        onBackgroundClick={() => {
          toggleAuthModal();
        }}
        onEscapeKeydown={() => {
          toggleAuthModal();
        }}
      >
        {isUserLoggedIn ? (
          <>
            <STH2>Are you sure you want to Logout?</STH2>
            <STFlex flexDirection="row" width="50%" p={3}>
              <STButton
                variant="primaryOutlined"
                onClick={() => {
                  toggleAuthModal();
                }}
              >
                No
              </STButton>
              <STButton
                variant="primary"
                ml={4}
                onClick={async () => {
                  logLogoutEvent(user?.userId);
                  await signOut();
                  toggleAuthModal();
                }}
              >
                Yes
              </STButton>
            </STFlex>
          </>
        ) : (
          <>
            <STH2 variant="blackBold">Welcome to SastaVisa</STH2>
            <Box height="4px" bg="secondary" width="85px" mt={1}>
              <Box width="120px" bg="secondary" />
            </Box>
            <Box
              boxShadow={0}
              px={2}
              py={2}
              mt={5}
              width="50%"
              onClick={async () => {
                await signInWithGoogle();
                if (!isAuthenticating) {
                  logLoginEvent();
                  toggleAuthModal();
                }
              }}
              display="flex"
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
              borderRadius="7px"
            >
              <Box mr={3}>
                <img src={gIcon} height="25" width="25" alt="Google Icon" />
              </Box>
              <BaseText variant="blackBold" fontSize={2}>
                Login with Google
              </BaseText>
            </Box>
          </>
        )}
      </STModal>
    </ModalProvider>
  );
};

export default AuthModal;
