import {
  useQuery,
  BaseQueryOptions,
  useMutation,
  queryCache,
} from 'react-query';
import { camelizeKeys, decamelizeKeys } from 'humps';

import {
  Traveler,
  VisaApplication,
  Visa,
  Requirement,
  RequirementCategory,
  VisaApplicationRequirement,
} from 'src/types';
import { db } from 'src/utils/firebase';

type CreateTravelerArgs = {
  managedBy?: string | null;
  application: VisaApplication;
  name: string;
  relation: string;
  travelerId?: string;
  visa?: Visa | null;
};

type UpdateTravelerArgs = {
  traveler?: Traveler;
  data: any;
};

type DeleteTravelerArgs = {
  traveler: Traveler;
};

export const getTraveler = async (_, { travelerId }) => {
  const travelerSnapshot = await db
    .collection('travelers')
    .doc(travelerId)
    .get();
  const traveler = travelerSnapshot.exists ? travelerSnapshot.data() : null;

  return traveler;
};

const useGetTraveler = (travelerId, options?: BaseQueryOptions) => {
  return useQuery(['traveler', { travelerId }], getTraveler, {
    ...options,
  });
};

const addTraveler = async ({
  managedBy,
  name,
  relation,
  application,
  travelerId,
  visa,
}: CreateTravelerArgs) => {
  let newTravelerId;
  if (!travelerId) {
    //if new traveler then add new traveler first
    const travelerSnapshot = await db.collection('travelers').add({
      managed_by: managedBy,
      name,
      relation,
      deleted: false,
    });

    newTravelerId = travelerSnapshot.id;
  } else {
    newTravelerId = travelerId;
  }

  const visaApplicationSnapshot = await db
    .collection('visa_applications')
    .doc(application.id)
    .get();
  const visaApplicationData = visaApplicationSnapshot.data();

  if (newTravelerId) {
    //now update traveler id in visa application
    await visaApplicationSnapshot.ref.update({
      traveler_id: newTravelerId,
    });

    //if already exist traveler then prefilled its data
    if (travelerId) {
      const previousRequirementsSnapshot = await db
        .collection('visa_application_requirements')
        .where('traveler_id', '==', travelerId)
        .where('is_active', '==', true)
        .get();

      // If there are previously filled requirements, fetch those and update visa application
      if (!previousRequirementsSnapshot.empty) {
        let activeRequirements: any = [];
        previousRequirementsSnapshot.forEach(doc => {
          activeRequirements.push(camelizeKeys(doc.data()));
        });

        let visaApplicationRequirements =
          visaApplicationData?.visa_requirements;
        let isVisaApplicationCompleted = true;

        activeRequirements.forEach(activeRequirement => {
          const reqDetails = visa?.requirements.find(
            requirement => requirement.id === activeRequirement.requirementId
          );
          if (reqDetails && reqDetails.isPrefilled) {
            if (
              Object.keys(visaApplicationRequirements).includes(
                activeRequirement.requirementId
              )
            ) {
              if (!activeRequirement.data || !activeRequirement.isCompleted) {
                isVisaApplicationCompleted = false;
              }
              visaApplicationRequirements[
                activeRequirement.requirementId
              ] = decamelizeKeys(activeRequirement.data);
            }
          } else {
            if (
              reqDetails &&
              reqDetails.id &&
              Object.keys(visaApplicationRequirements).includes(reqDetails?.id)
            ) {
              visaApplicationRequirements[reqDetails?.id] = null;
              isVisaApplicationCompleted = false;
            }
          }
        });

        await visaApplicationSnapshot.ref.update({
          is_completed: isVisaApplicationCompleted,
          visa_requirements: visaApplicationRequirements,
        });

        return;
      }
    }

    // If the traveler is new or they don't have any previously filled requirements
    await visaApplicationSnapshot.ref.update({
      is_completed: false,
      visa_requirements: visa?.requirements.reduce((acc, req) => {
        acc[req?.id!] = null;
        return acc;
      }, {}),
    });
  }
};

const useAddTraveler = () => {
  return useMutation(addTraveler, {
    onSettled: () => {
      queryCache.invalidateQueries(['visa-application-group']);
      queryCache.invalidateQueries(['my-travelers']);
    },
  });
};

const getMyTravelers = async (_, { managedBy }) => {
  if (!managedBy) {
    return null;
  }

  let travelers: Array<Traveler> = [];
  const travelersSnapshot = await db
    .collection('travelers')
    .where('managed_by', '==', managedBy)
    .where('deleted', '==', false)
    .get();

  travelersSnapshot.docs.forEach(async doc => {
    travelers.push(camelizeKeys({ id: doc.id, ...doc.data() }));
  });
  return travelers;
};

const useGetMyTravelers = (managedBy: string, options?: BaseQueryOptions) => {
  return useQuery(['my-travelers', { managedBy }], getMyTravelers, {
    ...options,
    refetchOnWindowFocus: false,
  });
};

const getMyTravelersDetails = async (_, { managedBy }) => {
  if (!managedBy) {
    return null;
  }
  let myTravelersDetails: Traveler[] = [];

  //get all active requirements
  const activeRequirementsSnapshot = await db
    .collection('visa_requirements')
    .where('deleted', '==', false)
    .where('is_prefilled', '==', true)
    .get();

  const requirements: Requirement[] = [];
  for (let i = 0; i < activeRequirementsSnapshot.docs.length; i++) {
    let requirement = activeRequirementsSnapshot.docs[i].data();
    const categoryData: RequirementCategory = (
      await requirement.category.get()
    ).data();
    requirement = { ...requirement, category: categoryData.name };
    requirements.push(
      camelizeKeys({
        ...requirement,
        id: activeRequirementsSnapshot.docs[i].id,
      })
    );
  }

  if (requirements.length) {
    //get all travelers of a user
    const myTravelers = await getMyTravelers(_, { managedBy });
    if (myTravelers?.length) {
      for (let i = 0; i < myTravelers.length; i++) {
        const traveler: Traveler = myTravelers[i];
        //get Traveler all active requirements
        const travelerActiveReqsSnapshot = await db
          .collection('visa_application_requirements')
          .where('traveler_id', '==', traveler.id)
          .where('is_active', '==', true)
          .get();

        if (!travelerActiveReqsSnapshot.empty) {
          let travelerRequirements = [...requirements];

          for (let j = 0; j < travelerActiveReqsSnapshot.docs.length; j++) {
            const travelerActiveRequirement: VisaApplicationRequirement = await camelizeKeys(
              travelerActiveReqsSnapshot.docs[j].data()
            );

            const { req, index } = getRequirementAndIndex(
              travelerRequirements,
              travelerActiveRequirement
            );

            //if data of active requirement exist then add that data to a specefic requirement
            if (req) {
              req.data = travelerActiveRequirement.data;
            }
            travelerRequirements[index] = req;
          }

          myTravelersDetails.push({
            ...traveler,
            requirements: travelerRequirements,
          });
        } else {
          myTravelersDetails.push({
            ...traveler,
            requirements,
          });
        }
      }
      return myTravelersDetails;
    }
  }

  return myTravelersDetails;
};

const getRequirementAndIndex = (
  travelerRequirements: Requirement[],
  travelerActiveRequirement: VisaApplicationRequirement
) => {
  let req: Requirement | any = {
    ...travelerRequirements.find(
      r => r.id === travelerActiveRequirement.requirementId
    ),
  };

  const index = travelerRequirements.findIndex(
    r => r.id === travelerActiveRequirement.requirementId
  );

  return { req, index };
};

const useGetMyTravelersDetails = (
  managedBy?: string | null,
  options?: BaseQueryOptions
) => {
  return useQuery(
    ['my-travelers-details', { managedBy }],
    getMyTravelersDetails,
    {
      refetchOnWindowFocus: false,
      cacheTime: 0,
      ...options,
    }
  );
};

const updateTraveler = async ({ traveler, data }: UpdateTravelerArgs) => {
  if (!traveler) return;
  const travelerSnapshot = await db
    .collection('travelers')
    .doc(traveler.id)
    .get();

  if (travelerSnapshot) {
    await travelerSnapshot.ref.update(decamelizeKeys({ ...data }));
  }
};

const useUpdateTraveler = managedBy => {
  return useMutation(updateTraveler, {
    onSettled: () => {
      queryCache.invalidateQueries(['my-travelers', { managedBy }]);
    },
  });
};

const deleteTravelers = async ({ traveler }: DeleteTravelerArgs) => {
  //soft delete the traveler by just updating its key to deleted true
  //we are completely removing traveler

  const travelerSnapshot = await db
    .collection('travelers')
    .doc(traveler.id)
    .get();

  if (travelerSnapshot.exists) {
    await travelerSnapshot.ref.update({
      deleted: true,
    });
  }
};

const useDeleteTraveler = () => {
  return useMutation(deleteTravelers, {
    onSettled: () => {
      queryCache.invalidateQueries(['my-travelers']);
      queryCache.invalidateQueries(['my-travelers-details']);
    },
  });
};

export {
  useGetTraveler,
  useAddTraveler,
  useGetMyTravelers,
  useGetMyTravelersDetails,
  useUpdateTraveler,
  useDeleteTraveler,
};
