import React, { FC, useRef } from 'react';
import { Box, StyledSystemProps } from '../Box';

interface STDropdownProps {
  children: Array<React.ReactNode> | React.ReactNode;
  trigger: Array<React.ReactNode> | React.ReactNode;
  isOpen: boolean;
  rightAligned?: boolean;
  toggle: Function;
}

interface STDropdownContentProps {
  children: Array<React.ReactNode> | React.ReactNode;
}

const STDropdownContent: FC<
  STDropdownContentProps & StyledSystemProps & { onClick: any }
> = ({ children, ...props }) => <Box {...props}>{children}</Box>;

export const STDropdown: FC<STDropdownProps & StyledSystemProps> = ({
  trigger,
  children,
  isOpen,
  rightAligned = false,
  toggle,
  ...props
}: STDropdownProps & StyledSystemProps) => {
  const ref = useRef<HTMLDivElement>(document.createElement('div'));

  React.useEffect(() => {
    // add when mounted
    document.addEventListener('mousedown', handleOutsideClick);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
    // eslint-disable-next-line
  }, []);

  const handleOutsideClick = e => {
    if (!ref.current.contains(e.target)) {
      // outside click
      toggle(false);
    }
  };

  return (
    <Box p={[0, 2]} {...props}>
      <div ref={ref} onClick={() => toggle(!isOpen)}>
        {trigger}
        {isOpen ? (
          <STDropdownContent
            backgroundColor="white"
            marginLeft={rightAligned ? -(255 - ref.current.clientWidth) : 0}
            onClick={() => toggle(!isOpen)}
            position="absolute"
            marginTop={2}
            boxShadow={0}
            minWidth="255px"
            zIndex={9}
          >
            {children}
          </STDropdownContent>
        ) : (
          ''
        )}
      </div>
    </Box>
  );
};
