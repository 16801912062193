import { useQuery } from 'react-query';
import { camelizeKeys } from 'humps';

import { db } from 'src/utils/firebase';
import { Country } from 'src/types';

const getCountries = async (): Promise<Country[]> => {
  const snapshot = await db
    .collection('countries')
    .where('is_active', '==', true)
    .get();
  return snapshot.docs.map(doc => {
    return camelizeKeys({ ...doc.data(), id: doc.id });
  });
};

const useGetCountries = () => {
  return useQuery('countries', getCountries, {
    retry: 0,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  });
};

export { useGetCountries };
