import React from 'react';

type NoticeModalProps = {
  isOpen: boolean;
  toggle: Function;
};

const NoticeModalContext = React.createContext<NoticeModalProps | undefined>(
  undefined
);

const NoticeModalProvider = (props: { children: React.ReactNode }) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <NoticeModalContext.Provider
      value={{
        isOpen,
        toggle,
      }}
      {...props}
    />
  );
};

const useNoticeModal = () => {
  const context = React.useContext(NoticeModalContext);

  if (!context) {
    throw new Error('useNoticeModal must be used within NoticeModalProvider');
  }
  return context;
};

export { useNoticeModal, NoticeModalProvider };
