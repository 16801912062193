import React, { FC } from 'react';
import SlidingPanel from 'react-sliding-side-panel';
import styled from 'styled-components';
import { Box } from 'design/atoms/Box';

interface STSlidingPanelProps {
  position?: string;
  children: Array<React.ReactNode> | React.ReactNode;
  isOpen: boolean;
  size?: number;
}

const StyledBox = styled(Box)`
  min-height: 100vh;
  z-index: 3;
  background-color: ${({ theme }) => theme.colors.background};
`;

export const STSlidingPanel: FC<STSlidingPanelProps> = ({
  position,
  children,
  isOpen,
  size,
}: STSlidingPanelProps) => {
  return (
    <SlidingPanel type={position} isOpen={isOpen} size={size}>
      <StyledBox>{children}</StyledBox>
    </SlidingPanel>
  );
};

STSlidingPanel.defaultProps = {
  position: 'bottom',
  size: 100,
};
