import { format } from 'date-fns';

import { CoverLetterInputs } from 'src/types';

const formatDate = date => {
  if (date) {
    return format(new Date(date), 'dd-MMM-yyyy');
  }
  return null;
};

export const getCoverLetterTemplate = (values: CoverLetterInputs) => {
  const template = `${values.visaOffice} of ${values.country || '{{Country}}'}
  ${values.visaOfficeAddress || '{{Address of the Consulate}}'}

  Respected  Sir/Madam,

  I, ${
    values.legalName || '{{Legal name}}'
  } would like to apply for a tourist visa to spend a vacations in ${
    values.country || '{{Country}}'
  } form ${formatDate(values.entryDate) || '{{Departure Date}}'} to ${
    formatDate(values.exitDate) || '{{Return Date}}'
  }.

  The main purpose for travel is tourism, to see the beautiful sights of ${
    values.country || '{{Country}}'
  }, particularly ${
    values.touristAttractions || '{{Name of tourist attractions}}'
  }. I am also looking forward to experiencing the culture, tasting the cuisine, and meeting the locals to experience everything the country has to offer.
  ${
    values.sponsor
      ? `
      During my trip, my ${
        values.sponsorRelationship || '{{Sponsor relationship}}'
      } ${values.sponsorName || '{{Sponsor name}}'}, who works as ${
          values.sponsorJobTitle || '{{Sponsor job title}}'
        } at ${
          values.sponsorPlaceOfEmployment || '{{Sponsor place of employment}}'
        } will sponsor my trip. They will cover my accomodations, food expenses, and plane tickets.
      `
      : ''
  }
  Please find the following documents to support the legitimacy of what is outlined this letter:

  ${values.documents || ''}

  Please don't hesitate to contact me if any further information is required. You can reach me at:

  ${values.contactNumber || '{{Contact no.}}'}
  ${values.email || '{{Email}}'}


  Sincerely,
  ${values.legalName || '{{Legal name}}'}
  `;

  return template;
};
