import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import 'nprogress/nprogress.css';

// We're importing .css because @font-face in styled-components causes font files
// to be constantly re-requested from the server (which causes screen flicker)
// https://github.com/styled-components/styled-components/issues/1593
import './styles/fonts.css';
import './styles/misc.css';
import App from 'src/App';
import { GlobalDataVariables } from 'src/types';

// Add dataLayer field type to window object else TS shouts about it
declare global {
  interface Window extends GlobalDataVariables {
    dataLayer: any[];
  }
}

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

ReactDOM.render(<App />, document.getElementById('root'));
