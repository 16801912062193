import { BaseQueryOptions, useMutation, useQuery } from 'react-query';

import { db } from 'src/utils/firebase';
import {
  getPhoneNumberInInternationalFormat,
  pushToDataLayer,
  setGlobalDataVariables,
} from 'src/utils/helpers';

type AddContactResponse = {
  submitted: boolean;
  doesUserExist: boolean;
};

type GetContactResponse = {
  phoneNumber?: string | null;
  doesUserExist?: boolean;
};

const addUser = async ({ userId }): Promise<void> => {
  const userSnapshot = await db
    .collection('users')
    .where('user_id', '==', userId)
    .get();

  if (!userSnapshot.docs.length) {
    pushToDataLayer({ Signup_Success: true });
    setGlobalDataVariables('Signed_Up_Success');
    await db.collection('users').add({
      user_id: userId,
    });
  }
};

const useAddUser = () => {
  return useMutation(addUser);
};

const addContactNumber = async ({
  userId,
  contactNo,
  updatePhoneNumber,
}): Promise<AddContactResponse | undefined> => {
  if (!userId) {
    return;
  }

  const userSnapshot = await db
    .collection('users')
    .where('user_id', '==', userId)
    .get();

  if (userSnapshot.docs.length) {
    await userSnapshot.docs[0].ref.update({
      contact_no: getPhoneNumberInInternationalFormat(contactNo),
      user_id: userId,
    });
    updatePhoneNumber(getPhoneNumberInInternationalFormat(contactNo));
    return { submitted: true, doesUserExist: true };
  } else {
    return { submitted: false, doesUserExist: false };
  }
};

const useAddContact = () => {
  return useMutation(addContactNumber);
};

const getUserContact = async (
  _,
  { userId }
): Promise<GetContactResponse | undefined> => {
  if (!userId) {
    return;
  }

  const userSnapshot = await db
    .collection('users')
    .where('user_id', '==', userId)
    .get();
  if (userSnapshot.docs.length) {
    const user = userSnapshot.docs[0].data();
    if (user?.contact_no) {
      return { phoneNumber: user.contact_no, doesUserExist: true };
    } else {
      return { phoneNumber: null, doesUserExist: true };
    }
  }
  return { doesUserExist: false };
};

const useGetContactNumber = (
  userId: string | null | undefined,
  options: BaseQueryOptions
) => {
  return useQuery(['user-contact', { userId }], getUserContact, {
    refetchOnWindowFocus: false,
    cacheTime: 0,
    ...options,
  });
};

export { useAddUser, useAddContact, useGetContactNumber };
