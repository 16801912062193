import React, { useState } from 'react';
import styled, { withTheme } from 'styled-components';
import {
  color,
  layout,
  flexbox,
  space,
  border,
  typography,
  shadow,
} from 'styled-system';
import { Box } from 'design/atoms/Box';
import { STIcon } from 'design/atoms/Icon';
import { STSlidingPanel } from 'design/atoms/SlidingPanel';
import MediaQuery from 'react-media';

export const InputStyled = styled.input`
  ${layout}
  ${flexbox}
  ${color}
  ${space}
  ${border}
  ${typography}
  ${shadow}
  transition: border 0.3s ease;
  box-sizing: border-box;
  &:focus {
      border: ${({ borderNone, theme }) =>
        borderNone ? 'none' : theme.borders[1]};
      box-shadow: ${({ borderNone, theme }) =>
        borderNone ? 'none' : theme.shadows[1]};
      outline: none;
  }
  font-family:${({ theme }) => theme.fonts.primary};
  &:disabled {
    background: ${({ theme }) => theme.colors.disabled};
  }
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }

`;

export const STInput = React.forwardRef(
  ({ error, fontSize, isFontBold, ...props }, ref) => {
    return (
      <InputStyled
        width="100%"
        height="40px"
        px="10px"
        py="10px"
        border={error ? 3 : 0}
        boxShadow={error ? 2 : 'none'}
        fontSize={fontSize ? fontSize : [0, 1]}
        fontWeight={isFontBold && 'bold'}
        borderRadius={1}
        ref={ref}
        {...props}
      ></InputStyled>
    );
  }
);

const StyledIcon = styled(Box)`
  color: ${({ theme }) => theme.colors.grey40};
  outline: none;
  ${InputStyled}:focus + & {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

// expecting feature icons
export const STInputWithIcon = React.forwardRef(
  ({ icon, theme, width, leftIcon, display = 'block', ...props }, ref) => (
    <Box position="relative" width={width} display={display}>
      {leftIcon ? (
        <>
          <StyledIcon position="absolute" left={10} top={10}>
            <STIcon icon={icon} size="sm" smSize="xs" />
          </StyledIcon>
          <STInput {...props} ref={ref} paddingLeft="40px" paddingRight="0px" />
        </>
      ) : (
        <>
          <STInput {...props} ref={ref} />
          <StyledIcon position="absolute" right={10} top={10}>
            <STIcon icon={icon} size="sm" smSize="xs" />
          </StyledIcon>
        </>
      )}
    </Box>
  )
);

export const STTextArea = React.forwardRef((props, ref) => (
  <STInput ref={ref} as="textarea" {...props} />
));

export const STMobileInput = withTheme(
  ({ value, setValue, items, theme, ...props }) => {
    const [isOpen, setOpened] = useState(false);
    return (
      <MediaQuery query={`screen and (min-width: ${theme.breakpoints[0]})`}>
        {matches =>
          !matches ? (
            <>
              <STInputWithIcon
                value={value ? value : ''}
                onClick={() => setOpened(true)}
                {...props}
              />
              <STSlidingPanel isOpen={isOpen}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  height={60}
                  pt={15}
                  px={15}
                  color="white"
                  width="100%"
                  bg="primary"
                >
                  <STIcon
                    icon="ArrowLeft"
                    onClick={() => setOpened(false)}
                    size="md"
                  />
                  <Box
                    pt={1}
                    onClick={() => {
                      setValue('');
                    }}
                  >
                    Clear
                  </Box>
                </Box>
                <Box p={3}>
                  <STInputWithIcon
                    mb={3}
                    value={value ? value : ''}
                    onChange={event => setValue(event.target.value)}
                    {...props}
                  />
                  {items}
                </Box>
              </STSlidingPanel>
            </>
          ) : (
            <STInputWithIcon
              value={value ? value : ''}
              onChange={event => setValue(event.target.value)}
              {...props}
            />
          )
        }
      </MediaQuery>
    );
  }
);
