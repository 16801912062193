import React from 'react';
import { Route, RouteProps, Redirect } from 'react-router-dom';
import { useAuth } from 'src/AppProviders/contexts';

interface ProtectedRouteProps extends Omit<RouteProps, 'component'> {
  component: React.ElementType;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  component: Component,
  ...rest
}) => {
  const { isUserLoggedIn } = useAuth();
  return (
    <Route
      {...rest}
      render={props => {
        if (isUserLoggedIn) {
          return <Component {...props} />;
        } else {
          // If not authenticated, redirect to home page
          return <Redirect from={`${rest.path}`} to="/" />;
        }
      }}
    />
  );
};

export default ProtectedRoute;
