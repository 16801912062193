import React from 'react';
import { STBodyText, STActiveLink } from 'design/atoms/Text';
import { STFlex } from '../Container';
import { withTheme } from 'styled-components';
import { Box } from '../Box';

interface NavItemProps {
  label: string;
  active?: boolean | undefined;
  theme: any;
  onClick?: Function | undefined;
}

export const STNavItem = withTheme(
  ({ label, active, theme, onClick }: NavItemProps) => {
    const Wrapper = active ? STActiveLink : STBodyText;
    return (
      <STFlex
        active={active}
        height={['100%', '64px']}
        position="relative"
        flexDirection="column"
        borderBottom={[theme.borders[0], 'none']}
        borderLeft={[
          active ? `2px solid ${theme.colors.primary}` : 'none',
          'none',
        ]}
        data-cy="nav-item"
        justifyContent={['flex-start', 'center']}
        alignItems={['flex-start', 'center']}
        onClick={() => {
          onClick && onClick();
        }}
      >
        <Wrapper
          pt={[2, 0]}
          pr={[0, 0]}
          pb={[2, 0]}
          pl={[3, 0]}
          textAlign={['left', 'center']}
          width={'fit-content'}
          flex={1}
          fontSize={[1, 2]}
        >
          {label}
        </Wrapper>
        {active && (
          <Box
            position="absolute"
            top="96%"
            mt="auto"
            height="3px"
            minWidth="50px"
            alignSelf="center"
            bg="primary"
            display={['none', 'unset']}
          />
        )}
      </STFlex>
    );
  }
);
