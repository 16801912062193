import React, { useEffect, useState } from 'react';

import { auth } from 'src/utils/firebase';
import { UserInfo } from 'src/types';
import { useAddUser, useGetContactNumber } from 'src/hooks/api';
import { useLoader } from 'src/hooks';
import {
  getPhoneNumberInLocalFormat,
  pushToDataLayer,
} from 'src/utils/helpers';
import { logSetUserId } from 'src/services/analytics.service';
import { INTERNAL_EMAILS, USER_TYPES } from 'src/constants';

type ContextProps = {
  user: UserInfo | null;
  isUserLoggedIn: boolean;
  isAuthenticating: boolean;
  isModalOpen: boolean;
  toggleAuthModal: Function;
  updatePhoneNumber: Function;
};

const initialAuthData = {
  user: null,
  isUserLoggedIn: false,
  isAuthenticating: true,
  isModalOpen: false,
  toggleAuthModal: () => {},
  updatePhoneNumber: () => {},
};

const AuthContext = React.createContext<ContextProps>(initialAuthData);

const AuthProvider = (props: { children: React.ReactNode }) => {
  const [user, setUser] = useState<UserInfo | null>(useAuth().user);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isAuthenticating, setIsAuthenticating] = useState<boolean>(true);
  const isUserLoggedIn = Boolean(user);
  const [addUser, { isLoading }] = useAddUser();
  const {
    data: contactNoObj,
    isSuccess: isContactNoSuccess,
  } = useGetContactNumber(user?.userId, { enabled: user });

  useEffect(() => {
    auth.onAuthStateChanged(async user => {
      if (user) {
        try {
          await addUser({ userId: user.uid });
          logSetUserId(user.uid);
          const userInformation: UserInfo = {
            userName: user.displayName,
            email: user.email,
            userId: user.uid,
            imageUrl: user.photoURL,
            phoneNumber: contactNoObj?.phoneNumber,
          };
          pushToDataLayer({ UserID: user.uid });

          // if user email is one of internal emails then user is of type 'Internal'
          pushToDataLayer({
            UserType: INTERNAL_EMAILS.includes(
              user.email?.slice(user.email?.indexOf('@') + 1) || ''
            )
              ? USER_TYPES.INTERNAL
              : USER_TYPES.LOGGED_IN,
          });
          setUser(userInformation);
        } catch (e) {}
      } else {
        pushToDataLayer({
          UserType: USER_TYPES.VISITOR,
        });
        setUser(null);
      }
      !isLoading && setIsAuthenticating(false);
    });
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if (isContactNoSuccess) {
      setUser({
        ...user!,
        phoneNumber: getPhoneNumberInLocalFormat(contactNoObj?.phoneNumber!),
      });
    }
    // eslint-disable-next-line
  }, [isContactNoSuccess, contactNoObj]);

  useLoader({ doneCondition: !isAuthenticating });

  const toggleAuthModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const updatePhoneNumber = phoneNumber => {
    if (isUserLoggedIn) {
      setUser(prev => ({
        ...prev!,
        phoneNumber: getPhoneNumberInLocalFormat(phoneNumber),
      }));
    }
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        isUserLoggedIn,
        isModalOpen,
        toggleAuthModal,
        isAuthenticating,
        updatePhoneNumber,
      }}
      {...props}
    />
  );
};

const useAuth = () => {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }
  return context;
};

export { useAuth, AuthProvider };
