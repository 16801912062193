import React from 'react';
import { withTheme } from 'styled-components';
import { Box } from 'design/atoms/Box';
import * as FeatherIcon from 'react-feather';

interface DpFeatherIconProps {
  icon: string;
  size: number;
  color?: string;
}

const Icon = (props: DpFeatherIconProps) => {
  const IconTag = FeatherIcon[props.icon];
  return <IconTag color={props.color} size={props.size} />;
};

export const STIcon = withTheme(
  ({ icon, size, color, smSize, theme, ...props }) => {
    if (smSize === null) {
      smSize = size;
    }
    return (
      <>
        <Box
          as="span"
          display={['none', 'inline-block']}
          x-type="desktop-icon"
          {...props}
        >
          <Icon
            icon={icon}
            color={theme.colors[color]}
            size={theme.icons[size]}
          />
        </Box>
        <Box
          as="span"
          display={['inline-block', 'none']}
          x-type="mobile-icon"
          {...props}
        >
          <Icon
            icon={icon}
            color={theme.colors[color]}
            size={theme.icons[smSize]}
          />
        </Box>
      </>
    );
  }
);

STIcon.defaultProps = {
  size: 'sm',
};
