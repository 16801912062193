import { useMutation, queryCache } from 'react-query';
import { decamelizeKeys } from 'humps';
import { firestore } from 'firebase/app';

import { Requirement, VisaApplication, Traveler } from 'src/types';
import { db } from 'src/utils/firebase';
import { isRequirementCompleted } from 'src/utils/helpers';

type FillVisaRequirementArgs = {
  managedBy?: string | null;
  requirement: Requirement;
  visaApplication?: VisaApplication;
  data: object | null;
  isMyTravelerEdit?: boolean;
  traveler?: Traveler;
};

const fillVisaRequirement = async ({
  managedBy,
  requirement,
  visaApplication,
  data,
  isMyTravelerEdit,
  traveler,
}: FillVisaRequirementArgs) => {
  if (!managedBy) return;

  //isMyTravelerEdit prop shows that file is uploading from my travelers page
  //we are passing traveler from my traveler page to use traveler id

  const previousRequirementsSnapshot = await db
    .collection('visa_application_requirements')
    .where(
      'traveler_id',
      '==',
      isMyTravelerEdit ? traveler?.id : visaApplication?.travelerId
    )
    .where('requirement_id', '==', requirement.id)
    .where('is_active', '==', true)
    .get();

  // If there are any previous active requirements, set their active status to false
  if (previousRequirementsSnapshot?.docs.length) {
    previousRequirementsSnapshot.forEach(snapshot => {
      snapshot.ref.update({
        is_active: false,
        updated_at: firestore.FieldValue.serverTimestamp(),
      });
    });
  }

  // Add new filled requirement to requirements dump
  await db
    .collection('visa_application_requirements')
    .doc()
    .set({
      data,
      is_completed: isRequirementCompleted({ requirement, data }),
      is_active: true,
      requirement_id: requirement.id,
      traveler_id: isMyTravelerEdit
        ? traveler?.id
        : visaApplication?.travelerId,
      created_at: firestore.FieldValue.serverTimestamp(),
      updated_at: firestore.FieldValue.serverTimestamp(),
    });

  if (visaApplication && !isMyTravelerEdit) {
    // Update visa application to add new data to requirement
    //update only when visa application requirement is filled
    const visaApplicationSnapshot = await db
      .collection('visa_applications')
      .doc(visaApplication.id)
      .get();

    const visaApplicationData = {
      ...visaApplication,
      visaRequirements: {
        ...visaApplication.visaRequirements,
        [requirement.id!]: data,
      },
    };

    const allVisaRequirementsSnapshot = (
      await db.collection('visa_requirements').get()
    ).docs;
    const allVisaRequirements: Requirement[] = [];
    allVisaRequirementsSnapshot.forEach(snapshot =>
      allVisaRequirements.push({
        ...snapshot.data(),
        id: snapshot.id,
      } as Requirement)
    );
    let isVisaApplicationCompleted = true;

    Object.keys(visaApplicationData.visaRequirements).forEach(reqId => {
      const isReqOptional = Boolean(
        allVisaRequirements.find(req => req.id === reqId)?.optional
      );
      if (!visaApplicationData.visaRequirements[reqId] && !isReqOptional) {
        isVisaApplicationCompleted = false;
      }
    });

    if (visaApplicationSnapshot.exists) {
      await visaApplicationSnapshot.ref.update({
        is_completed: isVisaApplicationCompleted,
        [`visa_requirements.${requirement.id}`]: Array.isArray(data)
          ? data.map(d => decamelizeKeys(d))
          : decamelizeKeys(data),
      });
    }
  }
};

const useFillVisaRequirement = () => {
  let options: any = {
    onSettled: () => {
      queryCache.invalidateQueries(['visa-application-group']);
      queryCache.invalidateQueries(['my-travelers-details']);
    },
  };
  return useMutation(fillVisaRequirement, options);
};

export { useFillVisaRequirement };
