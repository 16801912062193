import React from 'react';
import { Box } from './Box';
import styled from 'styled-components';

export const STContainer = ({ children, ...props }) => {
  return (
    <Box
      x-type="container"
      display="flex"
      alignItems="center"
      justifyContent="center"
      minWidth="100vw"
      minHeight="100vh"
      {...props}
    >
      {children}
    </Box>
  );
};

export const STFlex = ({ children, ...props }) => (
  <Box x-type="flexbox" display="flex" {...props}>
    {children}
  </Box>
);

const StyledCard = styled(Box)`
  animation-name: ${({ theme }) => theme.animations.fadeIn};
  animation-duration: 0.5s;
  transition-delay: 100ms;
`;

// TODO move to their own files?
export const STCard = ({ children, ...props }) => {
  return (
    <StyledCard
      x-type="card"
      backgroundColor="white"
      p={3}
      boxShadow={0}
      {...props}
    >
      {children}
    </StyledCard>
  );
};

export const STPageCard = ({ children, ...props }) => (
  <STCard
    width={['100%', 350]}
    height={['100vh', 'auto']}
    borderRadius={[0, 1]}
    {...props}
  >
    {children}
  </STCard>
);

export const STBreak = () => <STFlex flexBasis="100%" height="0" />;

export const STSpacer = props => {
  return <Box display="block" pt={4} {...props}></Box>;
};
