import { format } from 'date-fns';

export const getBankStatementTemplate = values => {
  const template = `
  To,
  The Branch Manager,
  ${values.bankName || '{{Bank Name}}'},
  ${values.branchName || '{{Branch Name}}'}


  Subject: Request for Bank Statement


  Respected Sir/Madam,

  This letter is to request a bank statement for my account with Account Title: ${
    values.accountTitle || '{{Account Title}}'
  } and Account No. ${values.accountNo || '{{Account No.}}'}, from ${
    values.startDate
      ? format(new Date(values.startDate), 'dd-MMM-yyyy')
      : '{{Start Date}}'
  } to ${
    values.endDate
      ? format(new Date(values.endDate), 'dd-MMM-yyyy')
      : '{{End Date}}'
  }.
  ${
    values.collector
      ? `
      For some reason I won't be able to collect it myself, therefore ${
        values.collectorName || '{{Collector Name}}'
      } holding CNIC no. ${
          values.collectorNIC || '{{Collector NIC}}'
        } will be collecting it.
      `
      : ''
  }

  Sincerely,

  `;

  return template;
};
