type DocType = {
  bankStatementLetter: 0;
  bankCertificateLetter: 1;
  employerLetter: 2;
  employeeLetter: 3;
  consulateLetter: 4;
  coverLetter: 5;
};

export const VISA_CATEGORIES = {
  identity: 'Identity',
  consulate: 'Consulate Details',
  trip: 'Trip Info',
  financial: 'Financial',
  employment: 'Employment',
  visa: 'Visa',
};

export const ACTION_TYPES = {
  fileUpload: 0,
  passportVerify: 1,
  visaDuration: 2,
  personalInformation: 3,
  parentInformation: 4,
  accomodations: 5,
};

export const FILE_SIZE_LIMIT = 2; // MBs

export const RELATIONS = [
  {
    name: 'Self',
  },
  {
    name: 'Parent',
  },
  {
    name: 'Sibling',
  },
  {
    name: 'Friend',
  },
  {
    name: 'Spouse',
  },
  {
    name: 'Colleague',
  },
  {
    name: 'Child',
  },
  {
    name: 'Other',
  },
];

export const NATIONALITY = [
  {
    name: 'Pakistani',
  },
];

export const COUNTRIES = [
  {
    name: 'Pakistan',
  },
  {
    name: 'Other',
  },
];

export const GENDERS = [
  {
    name: 'Male',
  },
  {
    name: 'Female',
  },
  {
    name: 'Others',
  },
];

export const VISA_APPLICATION_STATUS = {
  inProgress: 0,
  completed: 1,
};

export const VISA_TYPES = {
  tourist: 0,
  business: 1,
};

export const VISA_TYPES_LABELS = {
  [VISA_TYPES.tourist]: 'tourist',
  [VISA_TYPES.business]: 'business',
};

export const VISA_APPLICATION_STATUS_LABEL = {
  [VISA_APPLICATION_STATUS.inProgress]: 'In Progress',
  [VISA_APPLICATION_STATUS.completed]: 'Completed',
};

export const IMAGE_EXTENSIONS = ['jpg', 'jpeg', 'png'];

export const EMAIL_REGEX = /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const DOC_TYPES: DocType = {
  bankStatementLetter: 0,
  bankCertificateLetter: 1,
  employerLetter: 2,
  employeeLetter: 3,
  consulateLetter: 4,
  coverLetter: 5,
};

export const DOC_TYPES_LABELS = {
  [DOC_TYPES.bankStatementLetter]: 'Bank Statement Letter',
  [DOC_TYPES.bankCertificateLetter]: 'Bank Certificate Letter',
  [DOC_TYPES.employerLetter]: 'Employer Letter',
  [DOC_TYPES.employeeLetter]: 'Employee Letter',
  [DOC_TYPES.consulateLetter]: 'Consulate Letter',
  [DOC_TYPES.coverLetter]: 'Cover Letter',
};

export const FAQ_CATEGORIES = {
  visaDetails: 0,
  documentsRequired: 1,
  visaProcess: 2,
  okToBoard: 3,
  cancellationAndRejection: 4,
};

export const FAQ_CATEGORIES_LABELS = {
  [FAQ_CATEGORIES.visaDetails]: 'Visa Details',
  [FAQ_CATEGORIES.documentsRequired]: 'Documents Required',
  [FAQ_CATEGORIES.visaProcess]: 'Visa Process',
  [FAQ_CATEGORIES.okToBoard]: 'Okay To Board',
  [FAQ_CATEGORIES.cancellationAndRejection]: 'Cancellation and Rejection',
};

export const PAYMENT_METHODS = {
  bankTransfer: 0,
  creditDebitCard: 1,
};

export const PAYMENT_METHODS_LABELS = {
  [PAYMENT_METHODS.bankTransfer]: 'BT',
  [PAYMENT_METHODS.creditDebitCard]: 'CCDC',
};

export const OK_TO_BOARD_KEYS = {
  travelInsurance: 'travelInsurance',
  covidTest: 'covidTest',
  flightTickets: 'flightTickets',
  travelAlert: 'travelAlert',
};

export const OK_TO_BOARD_ITEMS_TYPES = {
  addons: 0,
  info: 1,
  survey: 2,
};

export const DEFAULT_VENDOR = 'NTB7Zmkx8HwiUyhgFOik';

export const CONTACT_REGEX = /^[0-9]{4}-[0-9]{7}$/;

export const MAX_TRAVELLERS = 6;

export const EXTRA_PROCESSING_MINUTES = 4320;

export const PASSPORT_NUMBER_REGEX = /^[A-Z]{2}\d{7}$/i;

export const SPLIT_FAQ_ANSWER = '@#$%^';

export const ENTRY_TYPES = {
  single: 'single',
  multiple: 'multiple',
};

export const SCREEN_NAMES = {
  home: 'Home',
  visaDetails: 'Visa Details',
  uploadDocuments: 'Upload Documents',
  header: 'Header',
  footer: 'Footer',
  myTravelers: 'My Travelers',
  review: 'Visa Application Review',
  success: 'Visa Application Success',
  myDocuments: 'My Documents',
  bankStatement: 'Bank Statement Letter',
  coverLetter: 'Cover Letter',
  myApplications: 'My Applications',
  termsAndCondition: 'Terms And Conditions',
};

export const BANK_TRANSFER_DETAILS = {
  accountTitle: 'SASTA TICKET PVT LTD',
  ibanNumber: 'PK55 MEZN 0001 1401 0335 5390',
  accountNumber: '0114-0103355390',
  bankName: 'Meezan Bank Ltd',
};

export const DATEPICKER_DEFAULT_CONFIG = {
  monthsShown: 1,
  showYearDropdown: true,
  dateFormat: 'MMMM d, yyyy',
  popperModifiers: {
    preventOverflow: {
      enabled: true,
    },
  },
  disabled: false,
};

export const BANK_NAMES = [
  'Al Baraka Bank',
  'Allied Bank',
  'Apna Microfinance Bank',
  'Askari Bank',
  'Bank Al Habib',
  'Bank Alfalah',
  'Bank Islami',
  'Bank of Khyber',
  'Bank of Punjab',
  'Citibank',
  'Dubai Islamic Bank',
  'FINCA Microfinance Bank',
  'Faysal Bank',
  'First Women Bank Limited',
  'Habib Bank Limited',
  'Habib Metropolitan Bank',
  'ICBC',
  'JS Bank',
  'MCB',
  'MBC Islamic Bank',
  'Meezan Bank Limited',
  'Mobilink Microfinance Bank Ltd',
  'NRSP Micofinance Bank Ltd',
  'National Bank of Pakistan',
  'Samba Bank',
  'Silk Bank',
  'Sindh Bank',
  'Soneri Bank',
  'Standard Chartered Bank',
  'Summit Bank',
  'Telenor Microfinance Bank',
  'The First Microfinance Bank Ltd',
  'U Microfinance Bank',
  'United Bank Limited',
];

export const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const COUNTRIES_LIST = [
  'Afghanistan',
  'Albania',
  'Algeria',
  'American Samoa',
  'Andorra',
  'Angola',
  'Anguilla',
  'Antarctica',
  'Antigua and Barbuda',
  'Argentina',
  'Armenia',
  'Aruba',
  'Australia',
  'Austria',
  'Azerbaijan',
  'Bahamas (the)',
  'Bahrain',
  'Bangladesh',
  'Barbados',
  'Belarus',
  'Belgium',
  'Belize',
  'Benin',
  'Bermuda',
  'Bhutan',
  'Bolivia (Plurinational State of)',
  'Bonaire, Sint Eustatius and Saba',
  'Bosnia and Herzegovina',
  'Botswana',
  'Bouvet Island',
  'Brazil',
  'British Indian Ocean Territory (the)',
  'Brunei Darussalam',
  'Bulgaria',
  'Burkina Faso',
  'Burundi',
  'Cabo Verde',
  'Cambodia',
  'Cameroon',
  'Canada',
  'Cayman Islands (the)',
  'Central African Republic (the)',
  'Chad',
  'Chile',
  'China',
  'Christmas Island',
  'Cocos (Keeling) Islands (the)',
  'Colombia',
  'Comoros (the)',
  'Congo (the Democratic Republic of the)',
  'Congo (the)',
  'Cook Islands (the)',
  'Costa Rica',
  'Croatia',
  'Cuba',
  'Curaçao',
  'Cyprus',
  'Czechia',
  "Côte d'Ivoire",
  'Denmark',
  'Djibouti',
  'Dominica',
  'Dominican Republic (the)',
  'Ecuador',
  'Egypt',
  'El Salvador',
  'Equatorial Guinea',
  'Eritrea',
  'Estonia',
  'Eswatini',
  'Ethiopia',
  'Falkland Islands (the) [Malvinas]',
  'Faroe Islands (the)',
  'Fiji',
  'Finland',
  'France',
  'French Guiana',
  'French Polynesia',
  'French Southern Territories (the)',
  'Gabon',
  'Gambia (the)',
  'Georgia',
  'Germany',
  'Ghana',
  'Gibraltar',
  'Greece',
  'Greenland',
  'Grenada',
  'Guadeloupe',
  'Guam',
  'Guatemala',
  'Guernsey',
  'Guinea',
  'Guinea-Bissau',
  'Guyana',
  'Haiti',
  'Heard Island and McDonald Islands',
  'Holy See (the)',
  'Honduras',
  'Hong Kong',
  'Hungary',
  'Iceland',
  'India',
  'Indonesia',
  'Iran (Islamic Republic of)',
  'Iraq',
  'Ireland',
  'Isle of Man',
  'Israel',
  'Italy',
  'Jamaica',
  'Japan',
  'Jersey',
  'Jordan',
  'Kazakhstan',
  'Kenya',
  'Kiribati',
  "Korea (the Democratic People's Republic of)",
  'Korea (the Republic of)',
  'Kuwait',
  'Kyrgyzstan',
  "Lao People's Democratic Republic (the)",
  'Latvia',
  'Lebanon',
  'Lesotho',
  'Liberia',
  'Libya',
  'Liechtenstein',
  'Lithuania',
  'Luxembourg',
  'Macao',
  'Madagascar',
  'Malawi',
  'Malaysia',
  'Maldives',
  'Mali',
  'Malta',
  'Marshall Islands (the)',
  'Martinique',
  'Mauritania',
  'Mauritius',
  'Mayotte',
  'Mexico',
  'Micronesia (Federated States of)',
  'Moldova (the Republic of)',
  'Monaco',
  'Mongolia',
  'Montenegro',
  'Montserrat',
  'Morocco',
  'Mozambique',
  'Myanmar',
  'Namibia',
  'Nauru',
  'Nepal',
  'Netherlands (the)',
  'New Caledonia',
  'New Zealand',
  'Nicaragua',
  'Niger (the)',
  'Nigeria',
  'Niue',
  'Norfolk Island',
  'Northern Mariana Islands (the)',
  'Norway',
  'Oman',
  'Pakistan',
  'Palau',
  'Palestine, State of',
  'Panama',
  'Papua New Guinea',
  'Paraguay',
  'Peru',
  'Philippines (the)',
  'Pitcairn',
  'Poland',
  'Portugal',
  'Puerto Rico',
  'Qatar',
  'Republic of North Macedonia',
  'Romania',
  'Russian Federation (the)',
  'Rwanda',
  'Réunion',
  'Saint Barthélemy',
  'Saint Helena, Ascension and Tristan da Cunha',
  'Saint Kitts and Nevis',
  'Saint Lucia',
  'Saint Martin (French part)',
  'Saint Pierre and Miquelon',
  'Saint Vincent and the Grenadines',
  'Samoa',
  'San Marino',
  'Sao Tome and Principe',
  'Saudi Arabia',
  'Senegal',
  'Serbia',
  'Seychelles',
  'Sierra Leone',
  'Singapore',
  'Sint Maarten (Dutch part)',
  'Slovakia',
  'Slovenia',
  'Solomon Islands',
  'Somalia',
  'South Africa',
  'South Georgia and the South Sandwich Islands',
  'South Sudan',
  'Spain',
  'Sri Lanka',
  'Sudan (the)',
  'Suriname',
  'Svalbard and Jan Mayen',
  'Sweden',
  'Switzerland',
  'Syrian Arab Republic',
  'Taiwan',
  'Tajikistan',
  'Tanzania, United Republic of',
  'Thailand',
  'Timor-Leste',
  'Togo',
  'Tokelau',
  'Tonga',
  'Trinidad and Tobago',
  'Tunisia',
  'Turkey',
  'Turkmenistan',
  'Turks and Caicos Islands (the)',
  'Tuvalu',
  'Uganda',
  'Ukraine',
  'United Arab Emirates (the)',
  'United Kingdom of Great Britain and Northern Ireland (the)',
  'United States Minor Outlying Islands (the)',
  'United States of America (the)',
  'Uruguay',
  'Uzbekistan',
  'Vanuatu',
  'Venezuela (Bolivarian Republic of)',
  'Viet Nam',
  'Virgin Islands (British)',
  'Virgin Islands (U.S.)',
  'Wallis and Futuna',
  'Western Sahara',
  'Yemen',
  'Zambia',
  'Zimbabwe',
  'Åland Islands',
];

export const VISA_OFFICE_TYPES = {
  EMBASSY: 'Embassy',
  CONSULATE: 'Consulate General',
};

export const ERRORS = {
  REQUIRED: 'Field is required',
  EMAIL: 'Email is invalid',
  CONTACT: 'Contact no. format should be XXXX-XXXXXXX',
};

export const USER_TYPES = {
  VISITOR: 'Visitor',
  INTERNAL: 'Internal',
  LOGGED_IN: 'LoggedIn',
};

export const INTERNAL_EMAILS = [
  'voxlabs.io',
  'sataticket.pk',
  'altanalytica.com',
];
